import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectId: 0,
    ohx_TokenFlag :false,
  },
  getters: {
    getohx_TokenFlag(state){
      return state.ohx_TokenFlag
    }
  },
  mutations: {
    refreshohx_TokenFlag(state,payload){
      state.ohx_TokenFlag = payload;
    }
  },
  actions: {},
  modules: {}
});
