<template>
  <div class="bigBox">
    <div id="allmap" style="width: 100%; height: 100vh"></div>
    <el-button class="location-button" @click="ll">标注位置</el-button>
    <el-button class="path-button" @click="hh">轨迹跟踪</el-button>
    <!-- <el-button class="zero-button" @click="back">恢复位置</el-button> -->
  </div>
</template>
<script>
// import {getAction} from '@/api/manage'
export default {
  data() {
    return {
      map: null,
      mapLocationObjs: {},
      locations: [],
    };
  },
  created() {
    // 默认加载show方法
    // var map = new BMapGL.Map('allmap');
    // this.map = map;
    // this.show();
    // this.putLocations();
  },
  mounted() {
    this.mapInit();
  },
  methods: {
    mapInit() {
      // GL版命名空间为BMapGL
      // 按住鼠标右键，修改倾斜角和角度
      var map = new BMapGL.Map("allmap");
      this.map = map; // 创建Map实例

      map.centerAndZoom(new BMapGL.Point(116.28019, 40.049191), 5); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      map.setMapType(BMAP_EARTH_MAP);
      window.map = map; // 创建信息窗口对象
      /*  var marker = new BMapGL.Marker(new BMapGL.Point(116.280190, 40.049191)) // 创建点
              map.addOverlay(marker)             // 将标注添加到地图中*/
      //this.show();
      let me = this;
      map.addEventListener('zoomend', function (e) {
      console.log(map.getZoom());
      me.$emit('zoomReceive',map.getZoom());
});
//  map.addEventListener('click', function (e) {
//     // 投影实例
// const projection = map.getProjection()

// // 地图div宽高
// const box = map.getSize()
// const zoom = window.Math.pow(2, 18 - map.getZoom())

// // 中心坐标 转 平面坐标
// const center = projection.lngLatToPoint(map.getCenter())

// // 测试坐标 转 平面坐标
// // 这里根据自己的需求，设置point坐标
// const point = projection.lngLatToPoint({ lng: 118.816832, lat: 31.981216 })

// // 像素坐标
// const x = Math.round((point.x - center.x) / zoom + box.width / 2)
// const y = Math.round((center.y - point.y) / zoom + box.height / 2)

// // 测试下 与pointToPixel转换是相同的
// const pixel = map.pointToPixel({ lng: 118.816832, lat: 31.981216 })
// console.log(pixel, x, y)

// });
    },
    // 获取后端的数据
    show() {
      // getAction("/sate/showMap").then((res) => {
      // if (res.success) {
      // this.mapLocationObjs = res;
      // this.locations = res.result;
      // console.log(this.locations);
      this.locations = [
        { lla: "116,40", status: "good", stationName: "1" },
        { lla: "80,100", status: "good", stationName: "6" },
      ];
      for (var i = 0; i < this.locations.length; i++) {
        // 单个卫星站对象
        var locationObject = this.locations[i];
        // 根据逗号分隔坐标
        var str = this.locations[i].lla.split(",");
        console.log(str);
        // 创建点
        var point = new BMapGL.Point(str[0], str[1]);
        var marker = new BMapGL.Marker(point);
        // 信息窗口展示
        this.putWindow(marker, point, locationObject);
        // 地图上创建点
        this.map.addOverlay(marker);
      }
      // }
      // })
    },
    // 标注弹框
    putWindow(marker, point, object) {
      // 卫星站对象
      var windowInfo = object;
      // 卫星站窗口
      var opts = {
        width: 250, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: object.stationName, // 信息窗口标题
      };
      marker.addEventListener("click", function (event) {
        alert(object);
        // var infoWindow = new BMapGL.InfoWindow(object.status, opts);
        // this.map.openInfoWindow(infoWindow, point);
      });
    },
    hh() {
      // GL版命名空间为BMapGL
      // 按住鼠标右键，修改倾斜角和角度
      // var bmap = new BMapGL.Map("allmap");    // 创建Map实例
      // bmap.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 17);  // 初始化地图,设置中心点坐标和地图级别
      //   bmap.enableScrollWheelZoom(true);     // 开启鼠标滚轮缩放
      var path = [
        {
          lng: 116.297611,
          lat: 41.047363,
        },
        // {
        //   lng: 116.302839,
        //   lat: 42.048219,
        // },
        // {
        //   lng: 116.308301,
        //   lat: 43.050566,
        // },
        // {
        //   lng: 116.305732,
        //   lat: 47.054957,
        // },
        // {
        //   lng: 116.304754,
        //   lat: 48.057953,
        // },
        // {
        //   lng: 116.306487,
        //   lat: 49.058312,
        // },
        {
          lng: 119.666666,
          lat: 66.058312,
        },
      ];
      var point = [];
      for (var i = 0; i < path.length; i++) {
        point.push(new BMapGL.Point(path[i].lng, path[i].lat));
      }
      var pl = new BMapGL.Polyline(point);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 10);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 12);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 13);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 15);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 16);
      // this.map.centerAndZoom(new BMapGL.Point(116.297611, 40.047363), 5);
      setTimeout(function () {
        var trackAni = new BMapGLLib.TrackAnimation(this.map, pl, {
          overallView: true,
          tilt: 30,
          duration: 2000,
          delay: 300,
        });
        trackAni.start();
      }, 1000);
    },
    ll() {
      this.locations = [
        { lla: "116.297611,41.047363", status: "good", stationName: "1" },
        { lla: "119.666666,66.058312", status: "good", stationName: "6" },
      ];
      for (var i = 0; i < this.locations.length; i++) {
        // 单个卫星站对象
        var locationObject = this.locations[i];
        // 根据逗号分隔坐标
        var str = this.locations[i].lla.split(",");
        console.log(str);
        // 创建点
        var point = new BMapGL.Point(str[0], str[1]);
        var marker = new BMapGL.Marker(point);
        // 信息窗口展示
        this.putWindow(marker, point, locationObject);
        // 地图上创建点
        this.map.addOverlay(marker);
      }
    },
    back() {
      this.map.centerAndZoom(new BMapGL.Point(116.28019, 40.049191), 5); // 初始化地图,设置中心点坐标和地图级别
    },
  },
};
</script>
<style lang="less" scoped>
.bigBox {
  position: fixed;
  background-image: linear-gradient(181deg, #7cd8da 0%, #daf6f7 39%, #e9e9e9 76%);
  width: 100vw;
  height: 100vh;
  .location-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10000000000;
  }
  .path-button {
    position: absolute;
    top: 10px;
    left: 100px;
    z-index: 10000000000;
  }
  .zero-button {
    position: absolute;
    top: 10px;
    left: px;
    z-index: 10000000000;
    margin-left: 200px;
  }
}
</style>
