<template>
  <div class="search-in">
    <div class="close-button" @click="onCancele">
      <i class="el-icon-circle-close"></i>
    </div>
    <div class="search-in-title">军舰轨迹大数据热力分析</div>
    <el-form ref="form" :model="sizeFormTrack">
      <el-form-item v-show="!false" style="margin-left: -10px" label="国家选择" class="form-item" required>
        <el-select
          v-model="selectTypeScope"
          style="width: 250px"
          filterable
          placeholder="请选择"
          clearable
          multiple
          @change="selectChangeScope"
        >
        <el-checkbox v-model="checkedScope" @change='selectAllScope' style="text-align:right;margin-left: 70%">全选</el-checkbox>
          <el-option
            v-for="item in selectTypesScope"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="margin-left: -10px" label="类型选择" class="form-item" required>
        <el-select
          v-model="selectType"
          style="width: 250px"
          filterable
          placeholder="请选择"
          clearable
          multiple
          @change="selectChange"
        >
        <el-checkbox v-model="checked" @change='selectAll' style="text-align:right;margin-left: 70%">全选</el-checkbox>
          <el-option
            v-for="item in selectTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="margin-left: -10px" label="选择时间" class="form-item" required>
        <el-date-picker
          v-model="timeArrT"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          style="width: 250px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item size="large" style="text-align: center; margin-top: 30px">
        <el-button id="btn-confirm" type="primary" @click="onSubmit" style="width: 100px"
          >查询</el-button
        >
        <el-button id="btn-cancel" style="margin-left: 30px; width: 100px" @click="onCancele"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "fileSelect",
  props: {},
  data() {
    return {
      selectType: "",
      selectTypes: [
        { label: "航空母舰", value: 0 },
        { label: "驱逐舰", value: 1 },
        { label: "巡洋舰", value: 2 },
        { label: "潜艇及潜艇母舰", value: 3 },
        { label: "两栖攻击舰", value: 4 },
        { label: "濒海战斗舰", value: 5 },
        { label: "船坞登陆舰", value: 6 },
        { label: "猎雷舰", value: 7 },
        { label: "补给舰", value: 8 },
        { label: "其他舰", value: 9 },
        { label: "海洋监测船", value: 10 },
        { label: "无人船", value: 11 },
      ],
      selectTypeScope: "",
      selectTypesScope: [
        { label: "美国", value: 1 },
        { label: "日本", value: 2 },
        { label: "韩国", value: 3 },
        { label: "澳大利亚", value: 4 },
        { label: "北约", value: 6 },
        { label: "东盟", value: 7 },
        { label: "AUKUS", value: 8 },
      ],
      timeArrT: "",
      sizeFormTrack: {
        date1: "",
        date2: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          
        ],
      },
      checkedScope: false,
      checked: false
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      console.log(
        "submit: " +
          " selectType: " + this.selectType +
          " timeArrT: " + this.timeArrT
      );
      if (this.timeArrT == null || this.timeArrT == "" || this.timeArrT == [] || this.selectType === "" || this.selectType === " " || this.selectType == [] || this.selectType.length < 1 || this.selectTypeScope === "" || this.selectTypeScope === " " || this.selectTypeScope == [] || this.selectTypeScope.length < 1) {
        this.$message({
          message: "国家、类型、时间不能为空！",
          type: "error",
          duration: 1600,
        });
        return;
      } else {
        let startTime = parseInt(new Date(this.timeArrT[0]).getTime() / 1000);
        let endTime = parseInt(new Date(this.timeArrT[1]).getTime() / 1000);
        // if (endTime - startTime > 2626560) {
        //   this.$message({
        //     message: "时间跨度不能超过一个月哦！",
        //     type: "error",
        //     duration: 1600,
        //   });
        //   return;
        // }
        console.log("返回值为：" + [this.selectType, startTime, endTime, this.selectTypeScope]);
        this.$emit("onHeatSubmit", [this.selectType, startTime, endTime, this.timeArrT, this.selectTypeScope]);
      }
    },
    onCancele() {
      this.$emit("onHeatCancel")
    },
    selectAll(){
    this.selectType = [];
      if (this.checked) {
        this.selectTypes.forEach((item) => { this.selectType.push(item.value )});
      } else {
        this.selectType = [];
      }
    },
    selectAllScope(){
    this.selectTypeScope = [];
      if (this.checkedScope) {
        this.selectTypesScope.forEach((item) => { this.selectTypeScope.push(item.value )});
      } else {
        this.selectTypeScope = [];
      }
    },
    selectChange(list){
      if(list.length != this.selectTypes.length){
        this.checked = false;
      }

      console.log("select" + this.selectType);
    },
    selectChangeScope(list){

     

      if(list.length != this.selectTypesScope.length){
        this.checkedScope = false;
      }

      console.log("select" + this.selectTypeScope);

      console.log(this.selectTypeScope);
    }
  },
};
</script>

<style scoped lang="less">
.search-in {
  width: 340px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray, 2px 0 3px -1px gray;

  color: white;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 30px;
  padding-top: 40px;

  #btn-confirm {
    background-color: #165184;
    border-color: #165184;
  }

  #btn-cancel {
    background-color: #3c3d3f;
    border-color: #3c3d3f;
    color: white;
  }

  .search-in-title {
    text-align: center;
    font-size: 15pt;
    //color: white;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  /deep/ .el-form-item__label {
    font-size: 12pt;
    color: #7cd8da;
  }

  .form-item {
    margin-top: 30px;
  }

  .close-button {
      position: absolute;
      top: 3px;
      right: 5px;
      z-index: 9277;
      font-size: 25px;
      color: #fff;
    }

}
</style>
