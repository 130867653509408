<template>
  <div class="toolbar-right">
    <div style="text-align: center; margin-bottom: 10px">热力图工具箱</div>
    <div class="close-button" @click="closeCop">
        <i class="el-icon-circle-close"></i>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true">
      <el-tab-pane label="类型选择" name="first">
        <div class="inside">
          <div
          v-for="i in 12"
          :key="'data' + i"
          v-if="data['type' + (i - 1)].length > 0"
          class="toolbar-line"
        >
          <span>{{ names[i - 1] }}</span>
          <div>
            <switch-btn @valueChange="updateValue(arguments, i - 1)"></switch-btn>
          </div>
        </div>
        </div>
        
      </el-tab-pane>
      <el-tab-pane label="国家选择" name="second">
        <div class="inside">
          <div v-for="i in 8" :key="'scope' + i" v-if="scope.indexOf(i) != -1" class="toolbar-line">
            <span>{{ namesScope[i - 1].name }}</span>
            <div>
              <switch-btn @valueChange="updateValueScope(arguments, i - 1)"></switch-btn>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import switchBtn from "../components/switch-btn.vue";
export default {
  components: { switchBtn },
  name: "fileSelect",
  props: ["data", "scope"],
  data() {
    return {
      activeName: "first",
      list: [],
      names: [
        "航空母舰",
        "驱逐舰",
        "巡洋舰",
        "潜艇及潜艇母舰",
        "两栖攻击舰",
        "濒海战斗舰",
        "船坞登陆舰",
        "猎雷舰",
        "补给舰",
        "其他舰",
        "海洋监测船",
        "无人船",
      ],
      listScope: [],
      namesScope: [
        {name: "美国", index: 0},
        {name: "日本", index: 1},
        {name: "韩国", index: 2},
        {name: "澳大利亚", index: 3},
        {name: "xx", index: 4},
        {name: "北约", index: 5},
        {name: "东盟", index: 6},
        {name: "AUKUS", index: 7},
      ],
      // data: {
      //   type0: [
      //     [32.71573, 242.81214, "366984000", "USA"],
      //     [32.718117, 242.80759999999998, "366984000", "USA"],
      //     [32.717804, 242.79303, "366984000", "USA"],
      //     [32.712963, 242.78048, "366984000", "USA"],
      //     [32.704216, 242.770424, "366984000", "USA"],
      //     [32.691647, 242.768036, "366984000", "USA"],
      //     [32.652653, 242.77384, "366984000", "USA"],
      //   ],
      //   type1: [
      //     [32.565144, 242.358665, "368926267", "USA"],
      //     [32.543186, 242.356514, "368926267", "USA"],
      //     [32.545963, 242.35696000000002, "368926267", "USA"],
      //     [32.527695, 242.35154, "368926267", "USA"],
      //     [32.532684, 242.35216, "368926267", "USA"],
      //     [32.469086, 242.345955, "368926267", "USA"],
      //     [32.43902, 242.34726, "368926267", "USA"],
      //     [32.4378, 242.35257000000001, "368926267", "USA"],
      //     [32.443966, 242.35351, "368926267", "USA"],
      //     [32.490448, 242.35345, "368926267", "USA"],
      //     [32.48683, 242.35357, "368926267", "USA"],
      //     [32.562153, 242.350784, "368926267", "USA"],
      //     [32.564373, 242.35958, "368926267", "USA"],
      //     [32.56412, 242.35734, "368926267", "USA"],
      //     [32.573742, 242.45664, "368926267", "USA"],
      //     [32.57348, 242.45327, "368926267", "USA"],
      //     [32.574066, 242.45969, "368926267", "USA"],
      //     [32.576103, 242.464035, "368926267", "USA"],
      //     [32.578262, 242.46301, "368926267", "USA"],
      //     [32.578926, 242.45659999999998, "368926267", "USA"],
      //     [32.57902, 242.45977, "368926267", "USA"],
      //     [32.578346, 242.36623, "368926267", "USA"],
      //     [32.58132, 242.36108000000002, "368926267", "USA"],
      //     [32.582527, 242.36627, "368926267", "USA"],
      //     [32.578804, 242.35971, "368926267", "USA"],
      //     [32.5784, 242.36324000000002, "368926267", "USA"],
      //     [32.578316, 242.36122, "368926267", "USA"],
      //     [32.584103, 242.36037, "368926267", "USA"],
      //     [32.583443, 242.37919, "368926267", "USA"],
      //     [32.586475, 242.38305, "368926267", "USA"],
      //     [32.584873, 242.38182, "368926267", "USA"],
      //     [32.675034, 242.45938, "368926267", "USA"],
      //     [32.682495, 242.466324, "368926267", "USA"],
      //     [32.68371, 242.46962000000002, "368926267", "USA"],
      //     [32.683014, 242.46719000000002, "368926267", "USA"],
      //     [32.68374, 242.47064, "368926267", "USA"],
      //     [32.683746, 242.47012999999998, "368926267", "USA"],
      //     [32.65778, 242.50959, "368926267", "USA"],
      //     [32.65924, 242.50725599999998, "368926267", "USA"],
      //     [32.656322, 242.514404, "368926267", "USA"],
      //     [32.656994, 242.51193999999998, "368926267", "USA"],
      //     [32.647266, 242.55906, "368926267", "USA"],
      //     [32.64787, 242.55651, "368926267", "USA"],
      //     [32.62288, 242.647415, "368926267", "USA"],
      //     [32.607136, 242.724915, "368926267", "USA"],
      //     [32.60658, 242.74635, "368926267", "USA"],
      //     [32.611477, 242.75136, "368926267", "USA"],
      //     [32.612103, 242.74937, "368926267", "USA"],
      //     [32.609386, 242.74644999999998, "368926267", "USA"],
      //     [32.606934, 242.750244, "368926267", "USA"],
      //     [32.607704, 242.7764, "368926267", "USA"],
      //     [32.60682, 242.77104, "368926267", "USA"],
      //     [32.61316, 242.78096, "368926267", "USA"],
      //     [32.642998, 242.79898, "368926267", "USA"],
      //     [32.64135, 242.798035, "368926267", "USA"],
      //     [32.643856, 242.79955999999999, "368926267", "USA"],
      //     [32.646233, 242.80631, "368926267", "USA"],
      //     [32.648006, 242.80387000000002, "368926267", "USA"],
      //     [32.647873, 242.80521, "368926267", "USA"],
      //     [32.641014, 242.79323599999998, "368926267", "USA"],
      //     [32.629833, 242.785324, "368926267", "USA"],
      //     [32.613853, 242.77856400000002, "368926267", "USA"],
      //     [32.569416, 242.73395, "368926267", "USA"],
      //     [32.57545, 242.7412, "368926267", "USA"],
      //     [32.570004, 242.71998000000002, "368926267", "USA"],
      //     [32.60947, 242.666, "368926267", "USA"],
      //     [32.614403, 242.662285, "368926267", "USA"],
      //     [32.63268, 242.65531, "368926267", "USA"],
      //     [32.635868, 242.65777, "368926267", "USA"],
      //     [32.63484, 242.65623, "368926267", "USA"],
      //     [32.635704, 242.65739000000002, "368926267", "USA"],
      //     [32.630096, 242.66529, "368926267", "USA"],
      //     [32.62301, 242.6648, "368926267", "USA"],
      //     [32.61389, 242.65372000000002, "368926267", "USA"],
      //     [32.613384, 242.65308, "368926267", "USA"],
      //     [32.61474, 242.65476, "368926267", "USA"],
      //     [32.60999, 242.64706999999999, "368926267", "USA"],
      //     [32.61081, 242.64541, "368926267", "USA"],
      //     [32.650253, 242.65269999999998, "368926267", "USA"],
      //     [32.649406, 242.65151, "368926267", "USA"],
      //     [32.648514, 242.65782000000002, "368926267", "USA"],
      //     [32.634785, 242.661, "368926267", "USA"],
      //     [32.62805, 242.66108, "368926267", "USA"],
      //     [32.622795, 242.658905, "368926267", "USA"],
      //     [32.598743, 242.65948500000002, "368926267", "USA"],
      //     [32.59611, 242.66180400000002, "368926267", "USA"],
      //     [32.595833, 242.66485, "368926267", "USA"],
      //     [32.599876, 242.66511500000001, "368926267", "USA"],
      //     [32.598595, 242.666016, "368926267", "USA"],
      //     [32.60293, 242.66215499999998, "368926267", "USA"],
      //     [32.602688, 242.66259, "368926267", "USA"],
      //     [32.627033, 242.61311, "368926267", "USA"],
      //     [32.62659, 242.61400600000002, "368926267", "USA"],
      //     [32.630337, 242.60871, "368926267", "USA"],
      //     [32.631588, 242.60929, "368926267", "USA"],
      //     [32.632046, 242.61310600000002, "368926267", "USA"],
      //     [32.63236, 242.611145, "368926267", "USA"],
      //     [32.61279, 242.63560999999999, "368926267", "USA"],
      //     [32.61182, 242.6489, "368926267", "USA"],
      //     [32.612347, 242.64004, "368926267", "USA"],
      //     [32.609604, 242.749756, "368926267", "USA"],
      //     [32.61098, 242.76713999999998, "368926267", "USA"],
      //     [32.61819, 242.77530000000002, "368926267", "USA"],
      //     [32.636395, 242.73381, "368926267", "USA"],
      //     [32.635857, 242.734535, "368926267", "USA"],
      //     [32.668423, 242.71059, "368926267", "USA"],
      //     [32.67394, 242.70292, "368926267", "USA"],
      //     [32.673977, 242.70407, "368926267", "USA"],
      //     [32.671833, 242.69547, "368926267", "USA"],
      //     [32.664597, 242.69623, "368926267", "USA"],
      //     [32.66719, 242.69506, "368926267", "USA"],
      //     [32.653877, 242.70631400000002, "368926267", "USA"],
      //     [32.648624, 242.71512, "368926267", "USA"],
      //     [32.65327, 242.71841, "368926267", "USA"],
      //     [32.656704, 242.69284, "368926267", "USA"],
      //     [32.655235, 242.622604, "368926267", "USA"],
      //     [32.693085, 242.51866, "368926267", "USA"],
      //     [32.693825, 242.51400999999998, "368926267", "USA"],
      //     [32.6842, 242.31883, "368926267", "USA"],
      //     [32.687283, 242.30804, "368926267", "USA"],
      //     [32.694683, 242.31367, "368926267", "USA"],
      //     [32.693573, 242.31031000000002, "368926267", "USA"],
      //     [32.694763, 242.31538, "368926267", "USA"],
      //     [32.692276, 242.35798, "368926267", "USA"],
      //     [32.695946, 242.36196, "368926267", "USA"],
      //     [32.6971, 242.35915, "368926267", "USA"],
      //     [32.682697, 242.86826000000002, "338977000", "USA"],
      //     [32.67924, 242.86431, "338977000", "USA"],
      //     [32.699608, 242.83633, "338977000", "USA"],
      //     [32.698074, 242.8385, "338977000", "USA"],
      //     [32.71552, 242.812225, "338977000", "USA"],
      //     [32.7136, 242.815445, "338977000", "USA"],
      //     [32.717796, 242.79372999999998, "338977000", "USA"],
      //     [32.712585, 242.78026599999998, "338977000", "USA"],
      //     [32.703888, 242.77087, "338977000", "USA"],
      //     [32.707394, 242.77381, "338977000", "USA"],
      //     [32.690807, 242.76873999999998, "338977000", "USA"],
      //     [32.69994, 242.769165, "338977000", "USA"],
      //     [32.678173, 242.77033, "338977000", "USA"],
      //     [32.654415, 242.77351, "338977000", "USA"],
      //     [32.635536, 242.77541, "338977000", "USA"],
      //     [32.626366, 242.769485, "338977000", "USA"],
      //     [32.596363, 242.73199499999998, "338977000", "USA"],
      //     [32.59527, 242.73673, "338977000", "USA"],
      //     [32.594086, 242.73745, "338977000", "USA"],
      //     [32.59268, 242.72755, "338977000", "USA"],
      //     [32.59345, 242.73458, "338977000", "USA"],
      //     [32.59215, 242.73265, "338977000", "USA"],
      //     [32.625896, 242.67552999999998, "338977000", "USA"],
      //     [32.677254, 242.581726, "338977000", "USA"],
      //     [32.68186, 242.57719, "338977000", "USA"],
      //     [32.684334, 242.57744, "338977000", "USA"],
      //     [32.685127, 242.57788, "338977000", "USA"],
      //     [32.68211, 242.57715000000002, "338977000", "USA"],
      //     [32.685825, 242.57846, "338977000", "USA"],
      //     [32.687225, 242.5803, "338977000", "USA"],
      //     [32.687138, 242.58808, "338977000", "USA"],
      //     [32.687935, 242.58246, "338977000", "USA"],
      //     [32.68501, 242.590836, "338977000", "USA"],
      //     [32.687954, 242.58551, "338977000", "USA"],
      //     [32.68501, 242.590836, "338977000", "USA"],
      //     [32.67442, 242.58927, "338977000", "USA"],
      //     [32.673126, 242.58702, "338977000", "USA"],
      //     [32.67414, 242.58889, "338977000", "USA"],
      //     [32.672363, 242.58291, "338977000", "USA"],
      //     [32.67676, 242.57548, "338977000", "USA"],
      //     [32.683975, 242.57732, "338977000", "USA"],
      //     [32.675743, 242.58899, "338977000", "USA"],
      //     [32.66961, 242.57380999999998, "338977000", "USA"],
      //     [32.669327, 242.57278, "338977000", "USA"],
      //     [32.670597, 242.57738, "338977000", "USA"],
      //     [32.666485, 242.55993999999998, "338977000", "USA"],
      //     [32.67336, 242.527336, "338977000", "USA"],
      //     [32.673412, 242.527794, "338977000", "USA"],
      //     [32.62844, 242.41237999999998, "338977000", "USA"],
      //     [32.627953, 242.16029, "338977000", "USA"],
      //     [32.627934, 242.16201, "338977000", "USA"],
      //     [32.630817, 241.96056, "338977000", "USA"],
      //     [32.62684, 241.62348, "338977000", "USA"],
      //     [32.62406, 241.57786, "338977000", "USA"],
      //     [32.61103, 241.45824399999998, "338977000", "USA"],
      //     [32.59494, 241.364105, "338977000", "USA"],
      //     [32.59043, 241.34345000000002, "338977000", "USA"],
      //     [32.554127, 241.2894, "338977000", "USA"],
      //     [32.51024, 241.279045, "338977000", "USA"],
      //     [32.475372, 241.27696, "338977000", "USA"],
      //     [32.469414, 241.273964, "338977000", "USA"],
      //     [32.47078, 241.27532, "338977000", "USA"],
      //     [32.463997, 241.26646399999998, "338977000", "USA"],
      //     [32.443924, 241.27375, "338977000", "USA"],
      //     [32.432556, 241.27438999999998, "338977000", "USA"],
      //     [32.42244, 241.26354, "338977000", "USA"],
      //     [32.41164, 241.24263000000002, "338977000", "USA"],
      //     [32.35932, 241.11906399999998, "338977000", "USA"],
      //     [32.358295, 241.11659, "338977000", "USA"],
      //     [32.3602, 241.1211, "338977000", "USA"],
      //     [32.32796, 241.05768, "338977000", "USA"],
      //     [32.323692, 240.99545999999998, "338977000", "USA"],
      //     [32.319008, 240.97007000000002, "338977000", "USA"],
      //     [13.458502, 144.66887, "382933000", "USA"],
      //     [13.458673, 144.66386, "382933000", "USA"],
      //     [13.455123, 144.65634, "382933000", "USA"],
      //     [13.451855, 144.62468, "382933000", "USA"],
      //     [13.44934, 144.52293, "382933000", "USA"],
      //     [13.448305, 144.43735, "382933000", "USA"],
      //     [13.451263, 144.35272, "382933000", "USA"],
      //     [13.45283, 144.5469, "382933000", "USA"],
      //     [13.454818, 144.5591, "382933000", "USA"],
      //     [13.450365, 144.5836, "382933000", "USA"],
      //     [13.47016, 144.58469, "382933000", "USA"],
      //     [13.476187, 144.58351, "382933000", "USA"],
      //     [13.477508, 144.552, "382933000", "USA"],
      //     [13.510888, 144.44441, "382933000", "USA"],
      //     [21.354982, 202.04836, "369970036", "USA"],
      //     [21.3553, 202.04863, "369970036", "USA"],
      //     [21.35495, 202.04843, "369970036", "USA"],
      //     [32.67878, 242.86656, "368771000", "USA"],
      //     [32.6189, 242.5813, "368721000", "USA"],
      //     [32.63843, 242.40488, "368721000", "USA"],
      //     [32.632816, 242.36991, "368721000", "USA"],
      //     [32.63223, 242.33892, "368721000", "USA"],
      //     [32.632153, 242.32241, "368721000", "USA"],
      //     [32.632298, 242.34798999999998, "368721000", "USA"],
      //     [32.632145, 242.16185000000002, "368721000", "USA"],
      //     [32.63231, 242.16758, "368721000", "USA"],
      //     [32.635044, 241.88012, "368721000", "USA"],
      //     [32.63506, 241.88866000000002, "368721000", "USA"],
      //     [32.63276, 241.754524, "368721000", "USA"],
      //     [32.632633, 241.76963, "368721000", "USA"],
      //     [32.63306, 241.59672999999998, "368721000", "USA"],
      //     [32.632816, 241.61624999999998, "368721000", "USA"],
      //     [32.630703, 241.47857, "368721000", "USA"],
      //     [32.630863, 241.37747000000002, "368721000", "USA"],
      //     [32.63087, 241.384766, "368721000", "USA"],
      //     [32.64346, 241.07473, "368721000", "USA"],
      //     [32.700043, 241.00874, "368721000", "USA"],
      //     [32.713104, 241.0069, "368721000", "USA"],
      //     [32.722515, 241.03348, "368721000", "USA"],
      //     [32.72875, 241.08967, "368721000", "USA"],
      //     [32.78724, 241.326775, "368721000", "USA"],
      //     [32.790646, 241.34395999999998, "368721000", "USA"],
      //     [32.785732, 241.354095, "368721000", "USA"],
      //     [32.78055, 241.34695, "368721000", "USA"],
      //     [32.74594, 241.1644, "368721000", "USA"],
      //     [32.745186, 241.15445, "368721000", "USA"],
      //     [32.739338, 241.14451, "368721000", "USA"],
      //     [32.741417, 241.133415, "368721000", "USA"],
      //     [32.734783, 241.10426, "368721000", "USA"],
      //     [32.730637, 241.073685, "368721000", "USA"],
      //     [32.71853, 241.01938, "368721000", "USA"],
      //     [32.715958, 241.017876, "368721000", "USA"],
      //     [32.671986, 241.0395, "368721000", "USA"],
      //     [32.64448, 241.06614000000002, "368721000", "USA"],
      //     [32.66322, 241.07854, "368721000", "USA"],
      //     [32.76079, 241.04910999999998, "368721000", "USA"],
      //     [32.761192, 241.04518000000002, "368721000", "USA"],
      //     [32.74395, 241.0181, "368721000", "USA"],
      //     [32.722786, 241.026886, "368721000", "USA"],
      //     [32.70654, 241.12276, "368721000", "USA"],
      //     [32.676537, 241.281006, "368721000", "USA"],
      //     [32.680233, 241.2933, "368721000", "USA"],
      //     [32.71144, 241.286446, "368721000", "USA"],
      //     [32.821198, 241.21248, "368721000", "USA"],
      //     [32.866165, 241.17919999999998, "368721000", "USA"],
      //     [32.87803, 241.181465, "368721000", "USA"],
      //     [32.878513, 241.186035, "368721000", "USA"],
      //     [32.87105, 241.19484, "368721000", "USA"],
      //     [32.838234, 241.22659, "368721000", "USA"],
      //     [32.80758, 241.26547, "368721000", "USA"],
      //     [32.786182, 241.30473, "368721000", "USA"],
      //     [32.779697, 241.306755, "368721000", "USA"],
      //     [32.779793, 241.29518000000002, "368721000", "USA"],
      //     [32.784485, 241.28434, "368721000", "USA"],
      //     [32.824314, 241.19939, "368721000", "USA"],
      //     [32.850826, 241.14678, "368721000", "USA"],
      //     [32.828922, 241.24656, "368721000", "USA"],
      //     [32.816444, 241.314545, "368721000", "USA"],
      //     [32.814117, 241.316444, "368721000", "USA"],
      //     [32.76902, 241.302895, "368721000", "USA"],
      //     [32.766834, 241.31436, "368721000", "USA"],
      //     [32.806606, 241.30532, "368721000", "USA"],
      //     [32.807133, 241.30103, "368721000", "USA"],
      //     [32.805077, 241.296875, "368721000", "USA"],
      //     [32.810837, 241.29441, "368721000", "USA"],
      //     [32.82554, 241.30055, "368721000", "USA"],
      //     [32.81174, 241.29967, "368721000", "USA"],
      //     [32.79089, 241.285706, "368721000", "USA"],
      //     [32.800346, 241.29315, "368721000", "USA"],
      //     [32.76447, 241.32115, "368721000", "USA"],
      //     [32.73786, 241.33787999999998, "368721000", "USA"],
      //     [32.726345, 241.33147400000001, "368721000", "USA"],
      //     [32.72531, 241.33395000000002, "368721000", "USA"],
      //     [32.777115, 241.30907000000002, "368721000", "USA"],
      //     [32.809784, 241.29729500000002, "368721000", "USA"],
      //     [32.812202, 241.29665, "368721000", "USA"],
      //     [32.813232, 241.30259, "368721000", "USA"],
      //     [32.76306, 241.33535, "368721000", "USA"],
      //     [32.75007, 241.34475, "368721000", "USA"],
      //     [32.722572, 241.31932, "368721000", "USA"],
      //     [32.695084, 241.30428, "368721000", "USA"],
      //     [32.699566, 241.30721, "368721000", "USA"],
      //     [32.679184, 241.28784000000002, "368721000", "USA"],
      //     [32.64492, 241.24648000000002, "368721000", "USA"],
      //     [32.655216, 241.22692, "368721000", "USA"],
      //     [32.66435, 241.22045, "368721000", "USA"],
      //     [32.673447, 241.200645, "368721000", "USA"],
      //     [32.679054, 241.17086999999998, "368721000", "USA"],
      //     [32.715828, 241.15363000000002, "368721000", "USA"],
      //     [32.75105, 241.17953, "368721000", "USA"],
      //     [32.83261, 241.29482000000002, "368721000", "USA"],
      //     [32.79625, 241.34843, "368721000", "USA"],
      //     [32.783653, 241.35278, "368721000", "USA"],
      //     [32.77054, 241.34842, "368721000", "USA"],
      //     [32.766834, 241.33768, "368721000", "USA"],
      //     [32.813145, 241.290955, "368721000", "USA"],
      //     [32.840015, 241.26771, "368721000", "USA"],
      //     [32.84353, 241.26882999999998, "368721000", "USA"],
      //     [32.844482, 241.27998, "368721000", "USA"],
      //     [32.81985, 241.33182499999998, "368721000", "USA"],
      //     [32.82269, 241.32604, "368721000", "USA"],
      //     [32.81985, 241.33182499999998, "368721000", "USA"],
      //     [32.787067, 241.37406, "368721000", "USA"],
      //     [32.741917, 241.37205, "368721000", "USA"],
      //     [32.71594, 241.33556, "368721000", "USA"],
      //     [32.697258, 241.30525, "368721000", "USA"],
      //     [32.694977, 241.24165, "368721000", "USA"],
      //     [32.699924, 241.22062, "368721000", "USA"],
      //     [32.71401, 241.20805000000001, "368721000", "USA"],
      //     [32.73963, 241.24213, "368721000", "USA"],
      //     [32.639618, 241.31472, "368721000", "USA"],
      //     [32.63213, 241.364136, "368721000", "USA"],
      //     [32.62591, 241.59708999999998, "368721000", "USA"],
      //     [32.625816, 241.59134, "368721000", "USA"],
      //     [32.62463, 241.711235, "368721000", "USA"],
      //     [32.626545, 241.8573, "368721000", "USA"],
      //     [36.944027, 283.666916, "338972000", "USA"],
      //     [36.945137, 283.663185, "338972000", "USA"],
      //     [36.968987, 283.66416, "338972000", "USA"],
      //     [36.987526, 283.68512, "338972000", "USA"],
      //     [36.997463, 283.700325, "338972000", "USA"],
      //     [37.00395, 283.721985, "338972000", "USA"],
      //     [37.00363, 283.77094, "338972000", "USA"],
      //     [36.947, 283.99264, "338972000", "USA"],
      //     [36.929436, 284.03782, "338972000", "USA"],
      //     [36.91578, 284.06232, "338972000", "USA"],
      //     [36.89961, 284.084335, "338972000", "USA"],
      //     [36.869526, 284.11844, "338972000", "USA"],
      //     [36.84975, 284.1519, "338972000", "USA"],
      //     [36.935715, 283.66712, "338847000", "USA"],
      //     [36.934982, 283.66261, "338847000", "USA"],
      //     [36.96711, 283.664536, "338847000", "USA"],
      //     [36.977833, 283.6735, "338847000", "USA"],
      //     [36.97583, 283.6708, "338847000", "USA"],
      //     [36.998795, 283.70293, "338847000", "USA"],
      //     [37.005177, 283.72883, "338847000", "USA"],
      //     [37.00785, 283.75554999999997, "338847000", "USA"],
      //     [36.957363, 283.9552, "338847000", "USA"],
      //     [36.945786, 283.999054, "338847000", "USA"],
      //     [36.934967, 284.03228, "338847000", "USA"],
      //     [36.915607, 284.06393, "338847000", "USA"],
      //     [36.85885, 284.13285, "338847000", "USA"],
      //     [32.688854, 242.763145, "389908000", "USA"],
      //     [32.688835, 242.76312000000001, "389908000", "USA"],
      //     [32.86019, 280.0356, "338831000", "USA"],
      //     [21.352112, 202.05331, "369952000", "USA"],
      //     [21.352133, 202.05353, "369952000", "USA"],
      //     [21.352135, 202.05331, "369952000", "USA"],
      //     [35.308964, 139.68262, "338816000", "USA"],
      //     [35.308353, 139.68143, "338816000", "USA"],
      //     [35.30961, 139.68178, "338816000", "USA"],
      //     [35.308624, 139.6833, "338816000", "USA"],
      //     [35.3096, 139.68146, "338816000", "USA"],
      //     [35.3087, 139.68262, "338816000", "USA"],
      //     [35.30906, 139.68246, "338816000", "USA"],
      //     [32.69522, 242.771965, "303891000", "USA"],
      //     [32.649162, 242.826416, "303891000", "USA"],
      //     [32.702255, 242.83240999999998, "303891000", "USA"],
      //     [32.662827, 242.57577, "303891000", "USA"],
      //     [32.689453, 242.58879000000002, "303891000", "USA"],
      //     [32.93823, 129.45488, "431999654", "JPN"],
      //     [32.91763, 129.44179, "431999654", "JPN"],
      //     [32.898266, 129.36256, "431999654", "JPN"],
      //     [32.883728, 129.35822, "431999654", "JPN"],
      //     [32.883102, 129.39641, "431999654", "JPN"],
      //     [32.86947, 129.35713, "431999654", "JPN"],
      //     [32.869324, 129.3038, "431999654", "JPN"],
      //     [32.874527, 129.30754, "431999654", "JPN"],
      //     [32.875477, 129.33083, "431999654", "JPN"],
      //     [32.879204, 129.31456, "431999654", "JPN"],
      //     [32.879364, 129.29921, "431999654", "JPN"],
      //     [32.874695, 129.29655, "431999654", "JPN"],
      //     [32.875813, 129.3483, "431999654", "JPN"],
      //     [32.880276, 129.3734, "431999654", "JPN"],
      //     [32.8866, 129.35056, "431999654", "JPN"],
      //     [32.88707, 129.32024, "431999654", "JPN"],
      //     [32.885174, 129.30826, "431999654", "JPN"],
      //     [32.87771, 129.32278, "431999654", "JPN"],
      //     [32.883472, 129.30722, "431999654", "JPN"],
      //     [32.879677, 129.29097, "431999654", "JPN"],
      //     [32.887917, 129.30556, "431999654", "JPN"],
      //     [32.89001, 129.28922, "431999654", "JPN"],
      //     [32.88497, 129.30013, "431999654", "JPN"],
      //     [32.895863, 129.34125, "431999654", "JPN"],
      //     [32.8904, 129.3257, "431999654", "JPN"],
      //     [32.88739, 129.33606, "431999654", "JPN"],
      //     [32.88814, 129.3077, "431999654", "JPN"],
      //     [32.88639, 129.32568, "431999654", "JPN"],
      //     [32.905594, 129.34598, "431999654", "JPN"],
      //     [32.908073, 129.38254, "431999654", "JPN"],
      //     [32.911594, 129.37772, "431999654", "JPN"],
      //     [32.909294, 129.38269, "431999654", "JPN"],
      //     [32.9056, 129.37857, "431999654", "JPN"],
      //     [32.911087, 129.3833, "431999654", "JPN"],
      //     [32.91794, 129.38474, "431999654", "JPN"],
      //     [32.9145, 129.38704, "431999654", "JPN"],
      //     [32.917316, 129.38675, "431999654", "JPN"],
      //     [32.921185, 129.40527, "431999654", "JPN"],
      //     [32.942543, 129.46419, "431999654", "JPN"],
      //     [32.941708, 129.47737, "431999654", "JPN"],
      //     [32.958263, 129.4791, "431999654", "JPN"],
      //     [33.005333, 129.50104, "431999654", "JPN"],
      //     [33.094227, 129.55559, "431999654", "JPN"],
      //     [33.10763, 129.57442, "431999654", "JPN"],
      //     [33.108612, 129.61646, "431999654", "JPN"],
      //     [33.10417, 129.63214, "431999654", "JPN"],
      //     [33.09528, 129.65149, "431999654", "JPN"],
      //     [33.096794, 129.67027, "431999654", "JPN"],
      //     [33.103283, 129.68771, "431999654", "JPN"],
      //     [33.135536, 129.71408, "431999654", "JPN"],
      //     [33.150547, 129.71121, "431999654", "JPN"],
      //     [33.156895, 129.7181, "431999654", "JPN"],
      //     [33.156437, 129.72481, "431999654", "JPN"],
      //     [33.157543, 129.72375, "431999654", "JPN"],
      //     [-31.8763, 115.578766, "503000127", "AUS"],
      //     [-31.891068, 115.591484, "503000127", "AUS"],
      //     [-31.91805, 115.61785, "503000127", "AUS"],
      //     [-31.93955, 115.63545, "503000127", "AUS"],
      //     [-31.97125, 115.6837, "503000127", "AUS"],
      //     [-31.965082, 115.673386, "503000127", "AUS"],
      //     [-31.982668, 115.70258, "503000127", "AUS"],
      //     [-31.976517, 115.69275, "503000127", "AUS"],
      //     [-31.98495, 115.7035, "503000127", "AUS"],
      //     [-32.007217, 115.70153, "503000127", "AUS"],
      //     [-32.01085, 115.70085, "503000127", "AUS"],
      //     [-32.00475, 115.70167, "503000127", "AUS"],
      //     [-32.025616, 115.697334, "503000127", "AUS"],
      //     [-32.03165, 115.6968, "503000127", "AUS"],
      //     [-32.025284, 115.69742, "503000127", "AUS"],
      //     [-32.0465, 115.69545, "503000127", "AUS"],
      //     [-32.057316, 115.694984, "503000127", "AUS"],
      //     [-32.059532, 115.699066, "503000127", "AUS"],
      //     [-32.05773, 115.70233, "503000127", "AUS"],
      //     [-32.059418, 115.69762, "503000127", "AUS"],
      //     [-32.0561, 115.703285, "503000127", "AUS"],
      //     [-32.0429, 115.7026, "503000127", "AUS"],
      //     [-32.0197, 115.6997, "503000127", "AUS"],
      //     [-32.02603, 115.7002, "503000127", "AUS"],
      //     [-32.0231, 115.6998, "503000127", "AUS"],
      //   ],
      //   type2: [
      //     [32.6688, 242.77167, "338824000", "USA"],
      //     [32.54442, 280.76778, "368891000", "USA"],
      //     [32.513794, 280.70567, "368891000", "USA"],
      //     [32.477467, 280.62817, "368891000", "USA"],
      //     [32.43309, 280.53534, "368891000", "USA"],
      //     [32.392776, 280.44397, "368891000", "USA"],
      //     [13.433463, 144.66165, "338912000", "USA"],
      //     [13.433428, 144.66168, "338912000", "USA"],
      //     [11.434845, 300.46377, "257406000", "USA"],
      //     [11.433318, 300.465263, "257406000", "USA"],
      //     [11.435085, 300.46609, "257406000", "USA"],
      //     [11.435727, 300.46349, "257406000", "USA"],
      //     [11.427655, 300.46922, "257406000", "USA"],
      //     [11.430985, 300.46449, "257406000", "USA"],
      //     [11.426542, 300.463192, "257406000", "USA"],
      //     [11.422642, 300.44055000000003, "257406000", "USA"],
      //     [11.40889, 300.403603, "257406000", "USA"],
      //     [11.265848, 300.097233, "257406000", "USA"],
      //     [11.202677, 299.95395, "257406000", "USA"],
      //     [11.06798, 299.674522, "257406000", "USA"],
      //     [11.051598, 299.63336, "257406000", "USA"],
      //     [11.053318, 299.626743, "257406000", "USA"],
      //     [11.049608, 299.617126, "257406000", "USA"],
      //     [11.046755, 299.61924, "257406000", "USA"],
      //     [11.049113, 299.622227, "257406000", "USA"],
      //     [11.050655, 299.621353, "257406000", "USA"],
      //     [11.060793, 299.609486, "257406000", "USA"],
      //     [11.04044, 299.62462, "257406000", "USA"],
      //     [11.057543, 299.608654, "257406000", "USA"],
      //     [11.039023, 299.6234, "257406000", "USA"],
      //     [11.04372, 299.616596, "257406000", "USA"],
      //     [11.052057, 299.622395, "257406000", "USA"],
      //     [11.048665, 299.61647800000003, "257406000", "USA"],
      //     [11.051747, 299.623077, "257406000", "USA"],
      //     [11.046218, 299.617012, "257406000", "USA"],
      //     [11.050108, 299.61777, "257406000", "USA"],
      //     [11.04794, 299.61395600000003, "257406000", "USA"],
      //     [11.049667, 299.620502, "257406000", "USA"],
      //     [11.048843, 299.619053, "257406000", "USA"],
      //     [11.054207, 299.61646, "257406000", "USA"],
      //     [11.061695, 299.616554, "257406000", "USA"],
      //     [11.059863, 299.61112, "257406000", "USA"],
      //     [11.056287, 299.612457, "257406000", "USA"],
      //     [11.059917, 299.60828, "257406000", "USA"],
      //     [11.059207, 299.611107, "257406000", "USA"],
      //     [11.057687, 299.60964, "257406000", "USA"],
      //     [11.039007, 299.62439, "257406000", "USA"],
      //     [11.056702, 299.608047, "257406000", "USA"],
      //     [11.039938, 299.6226, "257406000", "USA"],
      //     [11.046613, 299.612484, "257406000", "USA"],
      //     [11.051192, 299.612907, "257406000", "USA"],
      //     [11.05074, 299.62013, "257406000", "USA"],
      //     [11.04061, 299.621944, "257406000", "USA"],
      //     [11.050522, 299.621166, "257406000", "USA"],
      //     [11.046062, 299.61395600000003, "257406000", "USA"],
      //     [11.051027, 299.622997, "257406000", "USA"],
      //     [32.67567, 242.87663, "366967000", "USA"],
      //     [32.675674, 242.876625, "366967000", "USA"],
      //   ],
      //   type3: [
      //     [47.892876, 237.39898, "369000020", "USA"],
      //     [47.928223, 237.36283, "369000020", "USA"],
      //     [47.94366, 237.36288000000002, "369000020", "USA"],
      //     [48.031963, 237.37269600000002, "369000020", "USA"],
      //     [48.09928, 237.35196000000002, "369000020", "USA"],
      //     [48.14934, 237.288666, "369000020", "USA"],
      //     [48.16332, 237.2653, "369000020", "USA"],
      //     [48.19557, 237.19071, "369000020", "USA"],
      //     [48.20928, 236.951935, "369000020", "USA"],
      //     [48.222664, 236.78048, "369000020", "USA"],
      //     [48.23816, 236.63379, "369000020", "USA"],
      //     [48.26524, 236.33792, "369000020", "USA"],
      //     [48.291553, 236.07556, "369000020", "USA"],
      //     [48.307777, 236.00946, "369000020", "USA"],
      //     [48.482487, 235.382195, "369000020", "USA"],
      //     [48.199547, 234.87973, "369000020", "USA"],
      //     [35.07987, 269.8272, "368116540", "USA"],
      //     [35.07894, 269.82679, "368116540", "USA"],
      //     [35.07952, 269.82769, "368116540", "USA"],
      //   ],
      //   type4: [
      //     [47.654877, 237.63585, "366993810", "USA"],
      //     [47.663868, 237.614624, "366993810", "USA"],
      //     [47.662903, 237.61343399999998, "366993810", "USA"],
      //     [47.666058, 237.60128, "366993810", "USA"],
      //     [47.665382, 237.60433999999998, "366993810", "USA"],
      //     [47.67076, 237.59278, "366993810", "USA"],
      //     [47.669136, 237.59433, "366993810", "USA"],
      //     [47.677387, 237.58530000000002, "366993810", "USA"],
      //     [47.675533, 237.57334, "366993810", "USA"],
      //     [47.67791, 237.57891999999998, "366993810", "USA"],
      //     [47.667423, 237.56133, "366993810", "USA"],
      //     [47.670883, 237.565926, "366993810", "USA"],
      //     [47.658615, 237.552246, "366993810", "USA"],
      //     [47.661713, 237.55507, "366993810", "USA"],
      //     [47.646786, 237.54543999999999, "366993810", "USA"],
      //     [47.619854, 237.53696, "366993810", "USA"],
      //     [47.59366, 237.53447, "366993810", "USA"],
      //     [47.579075, 237.533615, "366993810", "USA"],
      //     [47.546326, 237.52814, "366993810", "USA"],
      //     [47.5245, 237.51993, "366993810", "USA"],
      //     [47.512863, 237.513466, "366993810", "USA"],
      //     [47.50043, 237.50761, "366993810", "USA"],
      //     [47.471363, 237.48922, "366993810", "USA"],
      //     [47.451687, 237.4813, "366993810", "USA"],
      //     [47.45529, 237.48242, "366993810", "USA"],
      //     [47.417458, 237.470146, "366993810", "USA"],
      //     [47.408722, 237.469604, "366993810", "USA"],
      //     [47.37464, 237.46471, "366993810", "USA"],
      //     [47.3561, 237.457794, "366993810", "USA"],
      //     [47.33477, 237.44651, "366993810", "USA"],
      //     [47.33813, 237.44817999999998, "366993810", "USA"],
      //     [47.323296, 237.443344, "366993810", "USA"],
      //     [47.32736, 237.443825, "366993810", "USA"],
      //     [47.312935, 237.443535, "366993810", "USA"],
      //     [47.291264, 237.45469, "366993810", "USA"],
      //     [47.28129, 237.45451, "366993810", "USA"],
      //     [47.266403, 237.44681500000002, "366993810", "USA"],
      //     [47.25351, 237.43596, "366993810", "USA"],
      //     [47.217968, 237.41253, "366993810", "USA"],
      //     [47.20806, 237.405045, "366993810", "USA"],
      //     [47.211304, 237.40789, "366993810", "USA"],
      //     [47.194412, 237.39155599999998, "366993810", "USA"],
      //     [47.17032, 237.365295, "366993810", "USA"],
      //     [47.17246, 237.36701, "366993810", "USA"],
      //     [47.160107, 237.35944, "366993810", "USA"],
      //     [47.16255, 237.36063000000001, "366993810", "USA"],
      //     [47.153633, 237.35736, "366993810", "USA"],
      //     [47.1396, 237.357704, "366993810", "USA"],
      //     [47.135147, 237.35437000000002, "366993810", "USA"],
      //     [47.14384, 237.35953999999998, "366993810", "USA"],
      //     [47.13897, 237.35753, "366993810", "USA"],
      //     [47.141148, 237.35845999999998, "366993810", "USA"],
      //     [47.13674, 237.35584, "366993810", "USA"],
      //     [47.13897, 237.35753, "366993810", "USA"],
      //     [47.13464, 237.35264, "366993810", "USA"],
      //     [47.13655, 237.35062399999998, "366993810", "USA"],
      //     [47.150913, 237.35342, "366993810", "USA"],
      //     [47.1662, 237.36133999999998, "366993810", "USA"],
      //     [47.177223, 237.3712, "366993810", "USA"],
      //     [47.196316, 237.39394, "366993810", "USA"],
      //     [47.223347, 237.41522, "366993810", "USA"],
      //   ],
      //   type5: [
      //     [32.67941, 242.870995, "368926014", "USA"],
      //     [32.67937, 242.87106, "368926014", "USA"],
      //     [32.622074, 242.68157, "369970910", "USA"],
      //     [32.618725, 242.64125, "369970910", "USA"],
      //     [32.619007, 242.64397, "369970910", "USA"],
      //     [32.6227, 242.63235500000002, "369970910", "USA"],
      //     [32.62065, 242.63545, "369970910", "USA"],
      //     [32.65823, 242.58144, "369970910", "USA"],
      //     [32.658134, 242.58157, "369970910", "USA"],
      //     [32.65988, 242.57925, "369970910", "USA"],
      //     [32.65628, 242.58407599999998, "369970910", "USA"],
      //     [32.701218, 242.52566000000002, "369970910", "USA"],
      //     [32.717846, 242.46438, "369970910", "USA"],
      //     [32.713432, 242.47782999999998, "369970910", "USA"],
      //     [32.74112, 242.40714, "369970910", "USA"],
      //     [32.75245, 242.39618000000002, "369970910", "USA"],
      //     [32.748466, 242.39981, "369970910", "USA"],
      //     [32.76063, 242.38366000000002, "369970910", "USA"],
      //     [32.757336, 242.39162399999998, "369970910", "USA"],
      //     [32.777695, 242.34865000000002, "369970910", "USA"],
      //     [32.77807, 242.34241500000002, "369970910", "USA"],
      //     [32.777103, 242.35036, "369970910", "USA"],
      //     [32.78054, 242.320915, "369970910", "USA"],
      //     [32.78876, 242.28489000000002, "369970910", "USA"],
      //     [32.79063, 242.26949000000002, "369970910", "USA"],
      //     [32.789383, 242.27442000000002, "369970910", "USA"],
      //     [32.79417, 242.27029, "369970910", "USA"],
      //     [32.793343, 242.28072, "369970910", "USA"],
      //     [32.796783, 242.28338, "369970910", "USA"],
      //     [32.80034, 242.27627999999999, "369970910", "USA"],
      //     [32.798286, 242.28259, "369970910", "USA"],
      //     [32.85478, 242.07709, "369970910", "USA"],
      //     [32.898426, 241.960304, "369970910", "USA"],
      //     [32.899075, 241.95863, "369970910", "USA"],
      //     [32.896744, 241.96465999999998, "369970910", "USA"],
      //     [32.92684, 241.88934999999998, "369970910", "USA"],
      //     [32.983627, 241.77639, "369970910", "USA"],
      //     [33.006763, 241.74856599999998, "369970910", "USA"],
      //     [33.007645, 241.74625, "369970910", "USA"],
      //     [33.005997, 241.74339, "369970910", "USA"],
      //     [33.001022, 241.74392, "369970910", "USA"],
      //     [32.9902, 241.764404, "369970910", "USA"],
      //     [32.984295, 241.77856, "369970910", "USA"],
      //     [32.984188, 241.77659, "369970910", "USA"],
      //     [32.992718, 241.77651, "369970910", "USA"],
      //     [33.037712, 241.73606, "369970910", "USA"],
      //     [33.058052, 241.6936, "369970910", "USA"],
      //     [33.053608, 241.69042000000002, "369970910", "USA"],
      //     [33.04249, 241.69639, "369970910", "USA"],
      //     [33.041744, 241.69807400000002, "369970910", "USA"],
      //     [33.034584, 241.71988, "369970910", "USA"],
      //     [33.03422, 241.72077000000002, "369970910", "USA"],
      //     [33.036476, 241.71474999999998, "369970910", "USA"],
      //     [32.98156, 241.85553, "369970910", "USA"],
      //     [32.9567, 241.91628, "369970910", "USA"],
      //     [32.95715, 241.91957, "369970910", "USA"],
      //     [32.960094, 241.92086999999998, "369970910", "USA"],
      //     [32.95684, 241.91923500000001, "369970910", "USA"],
      //     [32.963833, 241.91867000000002, "369970910", "USA"],
      //     [32.963017, 241.9195, "369970910", "USA"],
      //     [33.009644, 241.81042, "369970910", "USA"],
      //     [33.055073, 241.708, "369970910", "USA"],
      //     [33.048683, 241.70483000000002, "369970910", "USA"],
      //     [33.049442, 241.70384, "369970910", "USA"],
      //     [32.96088, 241.93818, "369970910", "USA"],
      //     [32.957882, 241.94718, "369970910", "USA"],
      //     [32.959183, 241.950424, "369970910", "USA"],
      //     [32.96149, 241.95131, "369970910", "USA"],
      //     [32.967144, 241.94389, "369970910", "USA"],
      //     [33.038048, 241.73957000000001, "369970910", "USA"],
      //     [33.04726, 241.672615, "369970910", "USA"],
      //     [33.052353, 241.650055, "369970910", "USA"],
      //     [33.05162, 241.550446, "369970910", "USA"],
      //     [33.047783, 241.50299, "369970910", "USA"],
      //     [33.098152, 241.453094, "369970910", "USA"],
      //     [33.108856, 241.44024000000002, "369970910", "USA"],
      //     [33.125435, 241.4142, "369970910", "USA"],
      //     [33.138348, 241.40034500000002, "369970910", "USA"],
      //     [33.153107, 241.38843500000002, "369970910", "USA"],
      //     [33.17436, 241.37713, "369970910", "USA"],
      //     [33.1836, 241.3739, "369970910", "USA"],
      //     [33.18537, 241.37536, "369970910", "USA"],
      //     [33.18612, 241.37882000000002, "369970910", "USA"],
      //     [33.17623, 241.39085, "369970910", "USA"],
      //     [33.149925, 241.41848, "369970910", "USA"],
      //     [33.096325, 241.47951999999998, "369970910", "USA"],
      //     [33.090504, 241.48446, "369970910", "USA"],
      //     [33.08692, 241.49864, "369970910", "USA"],
      //     [33.079357, 241.51079, "369970910", "USA"],
      //     [33.065586, 241.52258, "369970910", "USA"],
      //     [33.02252, 241.552765, "369970910", "USA"],
      //     [33.021687, 241.55934000000002, "369970910", "USA"],
      //     [33.023514, 241.57411000000002, "369970910", "USA"],
      //     [33.035873, 241.61241, "369970910", "USA"],
      //     [33.0405, 241.63231000000002, "369970910", "USA"],
      //     [33.035442, 241.64793, "369970910", "USA"],
      //     [33.036312, 241.64434, "369970910", "USA"],
      //     [33.048195, 241.656105, "369970910", "USA"],
      //     [33.14232, 241.565025, "369970910", "USA"],
      //     [33.139732, 241.56743, "369970910", "USA"],
      //     [33.147697, 241.57021, "369970910", "USA"],
      //     [33.12316, 241.58526999999998, "369970910", "USA"],
      //     [33.10533, 241.60938, "369970910", "USA"],
      //     [33.062572, 241.67552999999998, "369970910", "USA"],
      //     [33.058052, 241.68214999999998, "369970910", "USA"],
      //     [33.063686, 241.67382, "369970910", "USA"],
      //     [33.017475, 241.74036, "369970910", "USA"],
      //     [33.020264, 241.73636, "369970910", "USA"],
      //     [32.993366, 241.76057, "369970910", "USA"],
      //     [32.94507, 241.79449499999998, "369970910", "USA"],
      //     [32.903553, 241.88875000000002, "369970910", "USA"],
      //     [32.90233, 241.89122, "369970910", "USA"],
      //     [32.905697, 241.88432, "369970910", "USA"],
      //     [32.868736, 241.95698, "369970910", "USA"],
      //     [32.846756, 242.00441, "369970910", "USA"],
      //     [32.669903, 242.87741, "368926056", "USA"],
      //     [32.66989, 242.8774, "368926056", "USA"],
      //     [30.396894, 278.59238, "369970707", "USA"],
      //     [30.394768, 278.58831, "369970707", "USA"],
      //     [30.389122, 278.59173, "369970707", "USA"],
      //     [30.390446, 278.591385, "368926298", "USA"],
      //     [30.39048, 278.59137, "368926298", "USA"],
      //     [38.318497, 286.46535, "368926299", "USA"],
      //     [38.350082, 286.52255, "368926299", "USA"],
      //     [38.4546, 286.69019000000003, "368926299", "USA"],
      //     [38.499104, 286.79727, "368926299", "USA"],
      //     [38.59038, 286.9484, "368926299", "USA"],
      //     [38.692726, 287.100494, "368926299", "USA"],
      //     [38.79932, 287.239494, "368926299", "USA"],
      //     [39.094303, 287.59087999999997, "368926299", "USA"],
      //     [39.19498, 287.69598, "368926299", "USA"],
      //     [39.325104, 287.83797500000003, "368926299", "USA"],
      //     [39.67962, 288.23668, "368926299", "USA"],
      //     [39.722298, 288.28301999999996, "368926299", "USA"],
      //     [40.01916, 288.47342000000003, "368926299", "USA"],
      //     [40.385345, 288.66003, "368926299", "USA"],
      //     [40.52985, 288.66308, "368926299", "USA"],
      //     [40.57674, 288.66667, "368926299", "USA"],
      //     [40.635334, 288.676605, "368926299", "USA"],
      //     [40.940453, 288.673676, "368926299", "USA"],
      //     [41.165573, 288.64676, "368926299", "USA"],
      //     [41.215157, 288.64341, "368926299", "USA"],
      //     [41.345146, 288.64225, "368926299", "USA"],
      //     [41.367725, 288.63525, "368926299", "USA"],
      //     [41.383556, 288.63781, "368926299", "USA"],
      //     [41.388428, 288.63694, "368926299", "USA"],
      //     [41.424217, 288.62415, "368926299", "USA"],
      //     [41.44583, 288.61929, "368926299", "USA"],
      //     [41.463104, 288.62772, "368926299", "USA"],
      //     [41.479305, 288.65045, "368926299", "USA"],
      //     [41.475906, 288.64683, "368926299", "USA"],
      //     [41.492634, 288.65082, "368926299", "USA"],
      //     [41.503956, 288.65085999999997, "368926299", "USA"],
      //     [41.519493, 288.66067499999997, "368926299", "USA"],
      //     [41.52517, 288.66711, "368926299", "USA"],
      //     [41.527134, 288.673744, "368926299", "USA"],
      //     [41.5289, 288.672325, "368926299", "USA"],
      //     [41.529636, 288.68215, "368926299", "USA"],
      //     [30.388172, 278.539955, "369970969", "USA"],
      //     [30.388157, 278.539955, "369970969", "USA"],
      //     [32.676983, 242.86878000000002, "368872000", "USA"],
      //     [32.60627, 242.77648, "368872000", "USA"],
      //     [32.70082, 242.764534, "368872000", "USA"],
      //     [32.700806, 242.76451, "368872000", "USA"],
      //     [32.70081, 242.76448, "368872000", "USA"],
      //   ],
      //   type6: [
      //     [32.73633, 242.53300000000002, "368926266", "USA"],
      //     [32.757633, 242.61019, "368926266", "USA"],
      //     [28.594057, 282.33797500000003, "369970799", "USA"],
      //     [28.55392, 282.37501, "369970799", "USA"],
      //     [28.21091, 282.60902, "369970799", "USA"],
      //     [28.116846, 282.65942, "369970799", "USA"],
      //     [28.01625, 282.71846, "369970799", "USA"],
      //     [28.004091, 282.716805, "369970799", "USA"],
      //     [27.898098, 282.67113, "369970799", "USA"],
      //     [27.815544, 282.70714599999997, "369970799", "USA"],
      //     [27.61369, 282.87264, "369970799", "USA"],
      //     [27.21166, 283.12432, "369970799", "USA"],
      //     [27.002836, 283.26041, "369970799", "USA"],
      //     [26.956917, 283.29269, "369970799", "USA"],
      //     [26.896427, 283.33879, "369970799", "USA"],
      //     [26.849625, 283.37777, "369970799", "USA"],
      //     [26.825684, 283.39368, "369970799", "USA"],
      //     [26.735247, 283.44735000000003, "369970799", "USA"],
      //     [26.653662, 283.48975, "369970799", "USA"],
      //     [26.50256, 283.58017, "369970799", "USA"],
      //     [26.27774, 283.703476, "369970799", "USA"],
      //     [26.177118, 283.75554999999997, "369970799", "USA"],
      //     [26.145332, 283.774216, "369970799", "USA"],
      //     [26.057972, 283.84174, "369970799", "USA"],
      //     [25.966022, 283.85676, "369970799", "USA"],
      //     [25.810593, 283.87864, "369970799", "USA"],
      //     [25.798094, 283.87828, "369970799", "USA"],
      //     [25.747194, 283.85509, "369970799", "USA"],
      //     [25.699173, 283.86436000000003, "369970799", "USA"],
      //     [25.804106, 283.86307, "369970799", "USA"],
      //     [25.811272, 283.86644, "369970799", "USA"],
      //     [25.800646, 283.86525, "369970799", "USA"],
      //     [25.788662, 283.861725, "369970799", "USA"],
      //     [25.781418, 283.8259, "369970799", "USA"],
      //     [25.80895, 283.434296, "369970799", "USA"],
      //     [25.811125, 283.38639, "369970799", "USA"],
      //     [25.802992, 283.34448, "369970799", "USA"],
      //     [25.776724, 283.236786, "369970799", "USA"],
      //     [25.76409, 283.202705, "369970799", "USA"],
      //     [25.606552, 282.9917, "369970799", "USA"],
      //     [33.73527, 241.90888, "369970637", "USA"],
      //     [33.73518, 241.90879, "369970637", "USA"],
      //     [-27.368605, 153.17293, "369970662", "USA"],
      //     [-27.36863, 153.17296, "369970662", "USA"],
      //     [45.580753, 237.23585500000002, "368926288", "USA"],
      //     [45.626324, 237.18196, "368926288", "USA"],
      //     [45.729046, 237.12425000000002, "368926288", "USA"],
      //     [45.83446, 237.16012, "368926288", "USA"],
      //     [45.848377, 237.16825, "368926288", "USA"],
      //     [45.87064, 237.18486000000001, "368926288", "USA"],
      //     [45.895233, 237.18770999999998, "368926288", "USA"],
      //     [45.954994, 237.13499000000002, "368926288", "USA"],
      //     [45.93919, 237.14809400000001, "368926288", "USA"],
      //     [46.035976, 237.105446, "368926288", "USA"],
      //     [46.080708, 237.08762000000002, "368926288", "USA"],
      //     [46.116993, 237.04763, "368926288", "USA"],
      //     [46.140182, 237.00871999999998, "368926288", "USA"],
      //     [46.129208, 237.02659599999998, "368926288", "USA"],
      //     [46.168457, 236.95116000000002, "368926288", "USA"],
      //     [46.174515, 236.91415, "368926288", "USA"],
      //     [46.172997, 236.915596, "368926288", "USA"],
      //     [46.172314, 236.91402, "368926288", "USA"],
      //     [46.182053, 236.882126, "368926288", "USA"],
      //     [46.18288, 236.81994, "368926288", "USA"],
      //     [46.170197, 236.774216, "368926288", "USA"],
      //     [46.153873, 236.74032599999998, "368926288", "USA"],
      //     [46.148415, 236.720215, "368926288", "USA"],
      //     [46.151737, 236.73743000000002, "368926288", "USA"],
      //     [46.16227, 236.75855, "368926288", "USA"],
      //     [46.17401, 236.78861, "368926288", "USA"],
      //     [46.15063, 236.73612, "368926288", "USA"],
      //     [46.14766, 236.7253, "368926288", "USA"],
      //     [46.156776, 236.75106, "368926288", "USA"],
      //     [46.173126, 236.78767399999998, "368926288", "USA"],
      //     [46.166588, 236.76969, "368926288", "USA"],
      //     [46.14961, 236.73678999999998, "368926288", "USA"],
      //     [46.14744, 236.72631, "368926288", "USA"],
      //     [46.15863, 236.74987, "368926288", "USA"],
      //     [46.171146, 236.78968, "368926288", "USA"],
      //     [46.176754, 236.897255, "368926288", "USA"],
      //     [46.17364, 236.91489, "368926288", "USA"],
      //     [46.174824, 236.912, "368926288", "USA"],
      //     [46.16393, 236.955696, "368926288", "USA"],
      //     [46.14551, 236.999794, "368926288", "USA"],
      //     [46.126312, 237.03117, "368926288", "USA"],
      //     [46.13206, 237.02177, "368926288", "USA"],
      //     [46.080383, 237.08792, "368926288", "USA"],
      //     [46.019627, 237.12314600000002, "368926288", "USA"],
      //     [45.951855, 237.13628, "368926288", "USA"],
      //     [45.911354, 237.17549, "368926288", "USA"],
      //     [45.88843, 237.18872, "368926288", "USA"],
      //     [45.90003, 237.18526, "368926288", "USA"],
      //     [45.85902, 237.17869000000002, "368926288", "USA"],
      //     [45.846153, 237.16631999999998, "368926288", "USA"],
      //     [45.754917, 237.12205, "368926288", "USA"],
      //     [45.71832, 237.12455, "368926288", "USA"],
      //     [45.694027, 237.12859, "368926288", "USA"],
      //     [45.590416, 237.22135, "368926288", "USA"],
      //     [45.580853, 237.23575, "368926288", "USA"],
      //     [36.633286, 284.60007, "369914078", "USA"],
      //     [36.28835, 284.53123, "369914078", "USA"],
      //     [36.280823, 284.5348, "369914078", "USA"],
      //     [36.32963, 284.52816, "369914078", "USA"],
      //     [36.43541, 284.49894, "369914078", "USA"],
      //     [36.443764, 284.49397999999997, "369914078", "USA"],
      //     [36.427135, 284.4818, "369914078", "USA"],
      //     [36.343967, 284.50225, "369914078", "USA"],
      //     [36.323193, 284.510796, "369914078", "USA"],
      //     [36.31986, 284.524445, "369914078", "USA"],
      //     [36.32055, 284.55039999999997, "369914078", "USA"],
      //     [36.331116, 284.5782, "369914078", "USA"],
      //     [36.480614, 284.58376, "369914078", "USA"],
      //     [36.405197, 284.585815, "369914078", "USA"],
      //     [36.407677, 284.58117, "369914078", "USA"],
      //     [36.559624, 284.45957, "369914078", "USA"],
      //     [36.70512, 284.33406, "369914078", "USA"],
      //     [36.78642, 284.27841, "369914078", "USA"],
      //     [36.816673, 284.2509, "369914078", "USA"],
      //     [36.853992, 284.18787399999997, "369914078", "USA"],
      //     [36.874947, 284.14052, "369914078", "USA"],
      //     [36.93309, 284.07128, "369914078", "USA"],
      //     [36.9426, 284.04393, "369914078", "USA"],
      //     [36.946014, 283.95730000000003, "369914078", "USA"],
      //     [36.946117, 283.96051, "369914078", "USA"],
      //     [36.947407, 283.96058, "369914078", "USA"],
      //     [36.94714, 283.957855, "369914078", "USA"],
      //     [36.960464, 283.93643, "369914078", "USA"],
      //     [36.966984, 283.91751, "369914078", "USA"],
      //     [37.004196, 283.7758, "369914078", "USA"],
      //     [37.00889, 283.74807, "369914078", "USA"],
      //     [37.00772, 283.73066, "369914078", "USA"],
      //     [37.001263, 283.709274, "369914078", "USA"],
      //     [36.99126, 283.688065, "369914078", "USA"],
      //     [36.97692, 283.66765, "369914078", "USA"],
      //     [36.970215, 283.66394, "369914078", "USA"],
      //     [36.94557, 283.66312, "369914078", "USA"],
      //     [36.94182, 283.66356, "369914078", "USA"],
      //     [36.94143, 283.66652999999997, "369914078", "USA"],
      //     [32.710625, 242.77823, "368786000", "USA"],
      //     [32.714058, 242.782486, "368786000", "USA"],
      //     [32.70033, 242.76976000000002, "368786000", "USA"],
      //     [32.694855, 242.767876, "368786000", "USA"],
      //     [32.62368, 242.777534, "368786000", "USA"],
      //     [32.621723, 242.776505, "368786000", "USA"],
      //     [32.62065, 242.77442000000002, "368786000", "USA"],
      //     [32.62043, 242.77215, "368786000", "USA"],
      //     [32.622772, 242.77724, "368786000", "USA"],
      //     [32.61964, 242.76665500000001, "368786000", "USA"],
      //     [32.61631, 242.74090999999999, "368786000", "USA"],
      //     [32.616436, 242.73844, "368786000", "USA"],
      //     [32.616497, 242.74776, "368786000", "USA"],
      //     [32.622852, 242.66391, "368786000", "USA"],
      //     [32.62443, 242.60199, "368786000", "USA"],
      //     [32.62462, 242.60007000000002, "368786000", "USA"],
      //     [32.624275, 242.60502, "368786000", "USA"],
      //     [32.63898, 242.495255, "368786000", "USA"],
      //     [32.662613, 242.43763, "368786000", "USA"],
      //     [32.680485, 242.38536, "368786000", "USA"],
      //     [32.67957, 242.38831, "368786000", "USA"],
      //     [32.708614, 242.31133, "368786000", "USA"],
      //     [32.71792, 242.290245, "368786000", "USA"],
      //     [32.721188, 242.2881, "368786000", "USA"],
      //     [32.718597, 242.29255, "368786000", "USA"],
      //     [32.718887, 242.29224399999998, "368786000", "USA"],
      //     [32.67287, 242.33796, "368786000", "USA"],
      //     [32.676056, 242.33490999999998, "368786000", "USA"],
      //     [32.66644, 242.33652, "368786000", "USA"],
      //     [32.669594, 242.33895, "368786000", "USA"],
      //     [32.664246, 242.32979, "368786000", "USA"],
      //     [32.66464, 242.33167, "368786000", "USA"],
      //     [32.661877, 242.30286999999998, "368786000", "USA"],
      //     [32.66192, 242.30469, "368786000", "USA"],
      //     [32.66293, 242.295006, "368786000", "USA"],
      //     [32.70594, 242.22546, "368786000", "USA"],
      //     [32.70861, 242.22427, "368786000", "USA"],
      //     [32.706516, 242.22469999999998, "368786000", "USA"],
      //     [32.70825, 242.22977400000002, "368786000", "USA"],
      //     [32.698566, 242.263374, "368786000", "USA"],
      //     [32.694126, 242.265755, "368786000", "USA"],
      //     [32.6952, 242.26632999999998, "368786000", "USA"],
      //     [32.69342, 242.26092, "368786000", "USA"],
      //     [32.692707, 242.263145, "368786000", "USA"],
      //     [32.694252, 242.26337, "368786000", "USA"],
      //     [32.693943, 242.26362, "368786000", "USA"],
      //     [32.694775, 242.26099, "368786000", "USA"],
      //     [32.690342, 242.268616, "368786000", "USA"],
      //     [32.69109, 242.26641999999998, "368786000", "USA"],
      //     [32.686718, 242.32658, "368786000", "USA"],
      //     [32.688145, 242.33073000000002, "368786000", "USA"],
      //     [32.723717, 242.30155000000002, "368786000", "USA"],
      //     [32.737976, 242.28759, "368786000", "USA"],
      //     [32.73439, 242.28412, "368786000", "USA"],
      //     [32.725906, 242.292175, "368786000", "USA"],
      //     [32.662426, 242.36284, "368786000", "USA"],
      //     [32.63959, 242.48462999999998, "368786000", "USA"],
      //     [32.604576, 242.64263, "368786000", "USA"],
      //     [32.60663, 242.69661000000002, "368786000", "USA"],
      //     [32.614933, 242.80156, "368786000", "USA"],
      //     [32.612602, 242.80583000000001, "368786000", "USA"],
      //     [32.603012, 242.80939, "368786000", "USA"],
      //     [32.594196, 242.81435, "368786000", "USA"],
      //     [32.595898, 242.81767000000002, "368786000", "USA"],
      //     [32.619488, 242.805, "368786000", "USA"],
      //     [32.62267, 242.79928999999998, "368786000", "USA"],
      //     [32.622536, 242.8007, "368786000", "USA"],
      //     [32.6219, 242.7871, "368786000", "USA"],
      //     [32.564964, 242.78712000000002, "368786000", "USA"],
      //     [32.56038, 242.78933, "368786000", "USA"],
      //     [32.562683, 242.79371, "368786000", "USA"],
      //     [32.574688, 242.80126, "368786000", "USA"],
      //     [32.572536, 242.79944, "368786000", "USA"],
      //     [32.584053, 242.8091, "368786000", "USA"],
      //     [32.597527, 242.81568, "368786000", "USA"],
      //     [32.601654, 242.81555, "368786000", "USA"],
      //     [32.60107, 242.81305, "368786000", "USA"],
      //     [32.601585, 242.81351999999998, "368786000", "USA"],
      //     [32.594395, 242.81023399999998, "368786000", "USA"],
      //     [32.584145, 242.80656, "368786000", "USA"],
      //     [32.580082, 242.80396000000002, "368786000", "USA"],
      //     [32.579742, 242.80223, "368786000", "USA"],
      //     [32.594162, 242.78736, "368786000", "USA"],
      //     [32.59335, 242.7881, "368786000", "USA"],
      //     [32.603, 242.77344499999998, "368786000", "USA"],
      //     [32.60511, 242.77894600000002, "368786000", "USA"],
      //     [32.605263, 242.77760999999998, "368786000", "USA"],
      //     [32.603542, 242.784195, "368786000", "USA"],
      //     [32.604256, 242.78266000000002, "368786000", "USA"],
      //     [32.591454, 242.80156, "368786000", "USA"],
      //     [32.590374, 242.79922, "368786000", "USA"],
      //     [32.593464, 242.79904, "368786000", "USA"],
      //     [32.640438, 242.81448, "368786000", "USA"],
      //     [32.640514, 242.81458, "368786000", "USA"],
      //     [32.639256, 242.81445, "368786000", "USA"],
      //     [32.6401, 242.81597, "368786000", "USA"],
      //     [36.91594, 283.82955, "368928000", "USA"],
      //     [36.914993, 283.82819, "368928000", "USA"],
      //     [36.91666, 283.82303, "368928000", "USA"],
      //     [36.93431, 283.82231, "368928000", "USA"],
      //     [36.948235, 283.82417, "368928000", "USA"],
      //     [36.960262, 283.828186, "368928000", "USA"],
      //     [36.977005, 283.87612, "368928000", "USA"],
      //     [36.950535, 283.9808, "368928000", "USA"],
      //     [36.930256, 284.03751, "368928000", "USA"],
      //     [36.935173, 284.09494, "368928000", "USA"],
      //     [36.934437, 284.120766, "368928000", "USA"],
      //     [36.952446, 284.192604, "368928000", "USA"],
      //     [36.956924, 284.21648, "368928000", "USA"],
      //     [36.972603, 284.257576, "368928000", "USA"],
      //     [37.06182, 284.445465, "368928000", "USA"],
      //     [37.066063, 284.55182, "368928000", "USA"],
      //     [37.0502, 284.77483, "368928000", "USA"],
      //     [37.04609, 285.0005, "368928000", "USA"],
      //     [37.0342, 285.11246, "368928000", "USA"],
      //     [37.02629, 285.164825, "368928000", "USA"],
      //     [37.02091, 285.229515, "368928000", "USA"],
      //     [37.1684, 285.47081000000003, "368928000", "USA"],
      //     [32.688797, 242.85845, "338805000", "USA"],
      //     [36.918865, 283.81863, "368869000", "USA"],
      //     [36.919327, 283.82173, "368869000", "USA"],
      //     [36.947945, 283.82415000000003, "368869000", "USA"],
      //     [36.967754, 283.83074, "368869000", "USA"],
      //     [36.97597, 283.83725, "368869000", "USA"],
      //     [36.97906, 283.86814, "368869000", "USA"],
      //     [36.949173, 283.98142, "368869000", "USA"],
      //     [36.933216, 284.02998, "368869000", "USA"],
      //     [36.912926, 284.067535, "368869000", "USA"],
      //     [36.862705, 284.12863, "368869000", "USA"],
      //     [36.833633, 284.18868, "368869000", "USA"],
      //     [36.816826, 284.21736, "368869000", "USA"],
      //     [36.734814, 284.28612, "368869000", "USA"],
      //     [36.69972, 284.31803, "368869000", "USA"],
      //     [32.6734, 242.87815, "338830000", "USA"],
      //     [32.673355, 242.87815999999998, "338830000", "USA"],
      //   ],
      //   type7: [
      //     [-33.8406, 151.35323, "503125000", "AUS"],
      //     [-33.828983, 151.37056, "503125000", "AUS"],
      //     [-33.826683, 151.38184, "503125000", "AUS"],
      //     [-33.82615, 151.3745, "503125000", "AUS"],
      //     [-33.82873, 151.37715, "503125000", "AUS"],
      //     [-33.825317, 151.38123, "503125000", "AUS"],
      //     [-33.82835, 151.37662, "503125000", "AUS"],
      //     [-33.827633, 151.37343, "503125000", "AUS"],
      //     [-33.83158, 151.37177, "503125000", "AUS"],
      //     [-33.83465, 151.36845, "503125000", "AUS"],
      //     [-33.83362, 151.37086, "503125000", "AUS"],
      //     [-33.83525, 151.36702, "503125000", "AUS"],
      //     [-33.833683, 151.36748, "503125000", "AUS"],
      //     [-33.83307, 151.36916, "503125000", "AUS"],
      //     [-33.831284, 151.36813, "503125000", "AUS"],
      //     [-33.827885, 151.36903, "503125000", "AUS"],
      //     [-33.831768, 151.36862, "503125000", "AUS"],
      //     [-33.824184, 151.36734, "503125000", "AUS"],
      //     [-33.8262, 151.371, "503125000", "AUS"],
      //     [-33.82607, 151.37193, "503125000", "AUS"],
      //     [-33.825115, 151.3694, "503125000", "AUS"],
      //     [-33.825817, 151.37265, "503125000", "AUS"],
      //     [-33.8244, 151.37633, "503125000", "AUS"],
      //     [-33.8498, 151.19724, "503125000", "AUS"],
      //     [-33.575016, 151.3196, "503125000", "AUS"],
      //     [-33.57545, 151.31848, "503125000", "AUS"],
      //     [-33.57453, 151.32242, "503125000", "AUS"],
      //     [-37.10614, 149.92276, "503121000", "AUS"],
      //     [-37.10023, 149.92073, "503121000", "AUS"],
      //     [-37.093864, 149.92317, "503121000", "AUS"],
      //     [-37.089314, 149.93889, "503121000", "AUS"],
      //     [-37.06038, 149.99147, "503121000", "AUS"],
      //     [-37.02225, 150.01575, "503121000", "AUS"],
      //     [-36.91799, 150.06163, "503121000", "AUS"],
      //     [-36.90583, 150.06444, "503121000", "AUS"],
      //     [-36.80468, 150.0969, "503121000", "AUS"],
      //     [-36.783833, 150.11952, "503121000", "AUS"],
      //     [-36.744106, 150.15858, "503121000", "AUS"],
      //     [-36.71932, 150.18146, "503121000", "AUS"],
      //     [-36.70136, 150.19957, "503121000", "AUS"],
      //     [-36.689888, 150.20862, "503121000", "AUS"],
      //     [-36.683037, 150.21248, "503121000", "AUS"],
      //     [-36.664513, 150.22462, "503121000", "AUS"],
      //     [-36.30907, 150.31924, "503121000", "AUS"],
      //     [-36.230297, 150.32254, "503121000", "AUS"],
      //     [-36.1972, 150.32166, "503121000", "AUS"],
      //     [-36.262806, 150.32132, "503121000", "AUS"],
      //     [-35.09699, 150.76166, "503121000", "AUS"],
      //     [-35.092995, 150.75249, "503121000", "AUS"],
      //     [-35.092785, 150.73746, "503121000", "AUS"],
      //     [-35.0971, 150.7164, "503121000", "AUS"],
      //     [-35.095417, 150.71304, "503121000", "AUS"],
      //     [-35.091167, 150.74072, "503121000", "AUS"],
      //     [-35.089783, 150.74266, "503121000", "AUS"],
      //     [-35.0921, 150.72815, "503121000", "AUS"],
      //     [-35.09648, 150.71986, "503121000", "AUS"],
      //     [-35.090233, 150.73068, "503121000", "AUS"],
      //     [-35.09012, 150.7444, "503121000", "AUS"],
      //     [-35.093082, 150.7396, "503121000", "AUS"],
      //     [-35.097584, 150.72456, "503121000", "AUS"],
      //     [-35.098183, 150.71733, "503121000", "AUS"],
      //     [-35.104015, 150.7125, "503121000", "AUS"],
      //     [-35.10515, 150.71259, "503121000", "AUS"],
      //     [-35.10808, 150.71162, "503121000", "AUS"],
      //     [-35.1169, 150.70749, "503121000", "AUS"],
      //     [-35.105366, 150.71956, "503121000", "AUS"],
      //     [-35.105682, 150.72473, "503121000", "AUS"],
      //     [-35.10267, 150.71907, "503121000", "AUS"],
      //     [-19.787146, 148.56041, "503126000", "AUS"],
      //     [-19.840836, 148.65851, "503126000", "AUS"],
      //     [-19.856308, 148.68315, "503126000", "AUS"],
      //     [-19.89302, 148.70168, "503126000", "AUS"],
      //     [-19.929308, 148.71843, "503126000", "AUS"],
      //     [-20.006912, 148.75761, "503126000", "AUS"],
      //     [-20.067766, 148.78532, "503126000", "AUS"],
      //     [-20.20324, 148.85358, "503126000", "AUS"],
      //     [-20.388977, 148.91304, "503126000", "AUS"],
      //     [-20.443037, 148.94656, "503126000", "AUS"],
      //     [-20.531113, 148.99933, "503126000", "AUS"],
      //     [-20.59878, 149.068, "503126000", "AUS"],
      //     [-20.636667, 149.14348, "503126000", "AUS"],
      //     [-20.664358, 149.20255, "503126000", "AUS"],
      //     [-20.66493, 149.20369, "503126000", "AUS"],
      //     [-20.660126, 149.19426, "503126000", "AUS"],
      //     [-20.676353, 149.22536, "503126000", "AUS"],
      //     [-20.678482, 149.23314, "503126000", "AUS"],
      //     [-20.678795, 149.2349, "503126000", "AUS"],
      //     [-20.676353, 149.22536, "503126000", "AUS"],
      //     [-20.679499, 149.23929, "503126000", "AUS"],
      //     [-20.70372, 149.47969, "503126000", "AUS"],
      //     [-20.715498, 149.56929, "503126000", "AUS"],
      //     [-20.727196, 149.69171, "503126000", "AUS"],
      //     [-20.736841, 149.70483, "503126000", "AUS"],
      //     [-20.742233, 149.70903, "503126000", "AUS"],
      //     [-20.73509, 149.70311, "503126000", "AUS"],
      //     [-20.767643, 149.72876, "503126000", "AUS"],
      //     [-20.762169, 149.7241, "503126000", "AUS"],
      //     [-20.966667, 149.91258, "503126000", "AUS"],
      //     [-20.961792, 149.90814, "503126000", "AUS"],
      //     [-21.036156, 149.97227, "503126000", "AUS"],
      //     [-21.133497, 150.06383, "503126000", "AUS"],
      //     [-21.196411, 150.11948, "503126000", "AUS"],
      //     [-21.261475, 150.18057, "503126000", "AUS"],
      //     [-21.306198, 150.2192, "503126000", "AUS"],
      //     [-21.436583, 150.33759, "503126000", "AUS"],
      //     [-21.432861, 150.33435, "503126000", "AUS"],
      //     [-21.512495, 150.40445, "503126000", "AUS"],
      //     [-21.752056, 150.62354, "503126000", "AUS"],
      //     [-21.821024, 150.6845, "503126000", "AUS"],
      //     [-21.815916, 150.67998, "503126000", "AUS"],
      //     [-21.89351, 150.75352, "503126000", "AUS"],
      //     [-21.969273, 150.82031, "503126000", "AUS"],
      //     [-22.126047, 151.02664, "503126000", "AUS"],
      //     [-22.18545, 151.10307, "503126000", "AUS"],
      //     [-22.171062, 151.08273, "503126000", "AUS"],
      //     [-22.170525, 151.08145, "503126000", "AUS"],
      //     [-22.171978, 151.08105, "503126000", "AUS"],
      //     [-22.17772, 151.09395, "503126000", "AUS"],
      //     [-22.17757, 151.09833, "503126000", "AUS"],
      //     [-22.154661, 151.07368, "503126000", "AUS"],
      //     [-22.153053, 151.0716, "503126000", "AUS"],
      //     [-22.153824, 151.06949, "503126000", "AUS"],
      //     [-22.152267, 151.06956, "503126000", "AUS"],
      //     [-22.143682, 151.06165, "503126000", "AUS"],
      //     [-22.143457, 151.0594, "503126000", "AUS"],
      //     [-22.149797, 151.05952, "503126000", "AUS"],
      //     [-22.14914, 151.0595, "503126000", "AUS"],
      //     [-22.149925, 151.06836, "503126000", "AUS"],
      //     [-22.140196, 151.06653, "503126000", "AUS"],
      //     [-22.139158, 151.05928, "503126000", "AUS"],
      //     [-22.142483, 151.05507, "503126000", "AUS"],
      //     [-22.165937, 151.07617, "503126000", "AUS"],
      //     [-22.248837, 151.18623, "503126000", "AUS"],
      //     [-22.153831, 151.0697, "503126000", "AUS"],
      //     [-22.281317, 151.22823, "503126000", "AUS"],
      //     [-22.32606, 151.28687, "503126000", "AUS"],
      //     [-22.171406, 151.0833, "503126000", "AUS"],
      //     [-22.77763, 151.87651, "503126000", "AUS"],
      //     [-22.799473, 151.89972, "503126000", "AUS"],
      //     [-22.874048, 151.96935, "503126000", "AUS"],
      //   ],
      //   type8: [
      //     [12.663738, 139.45592, "368709000", "USA"],
      //     [12.640692, 139.29637, "368709000", "USA"],
      //     [12.517413, 138.49443, "368709000", "USA"],
      //     [12.444968, 137.96982, "368709000", "USA"],
      //     [12.420525, 137.82556, "368709000", "USA"],
      //     [12.412705, 137.80156, "368709000", "USA"],
      //     [12.403147, 137.75851, "368709000", "USA"],
      //     [12.347187, 137.38956, "368709000", "USA"],
      //     [12.271608, 136.95767, "368709000", "USA"],
      //     [12.180448, 136.33156, "368709000", "USA"],
      //     [12.16747, 136.22195, "368709000", "USA"],
      //     [12.138592, 136.0209, "368709000", "USA"],
      //     [12.013015, 135.21384, "368709000", "USA"],
      //     [11.987497, 135.02054, "368709000", "USA"],
      //     [11.975262, 134.95235, "368709000", "USA"],
      //     [11.960132, 134.87833, "368709000", "USA"],
      //     [11.941845, 134.74849, "368709000", "USA"],
      //     [11.887967, 134.39754, "368709000", "USA"],
      //     [38.09787, 237.73384, "303849000", "USA"],
      //     [38.09708, 237.73656, "303849000", "USA"],
      //     [38.097473, 237.735916, "303849000", "USA"],
      //     [38.08606, 237.74773, "303849000", "USA"],
      //     [38.078857, 237.75265000000002, "303849000", "USA"],
      //     [38.07336, 237.75489, "303849000", "USA"],
      //     [38.074753, 237.75463000000002, "303849000", "USA"],
      //     [38.0654, 237.75437, "303849000", "USA"],
      //     [38.064873, 237.75477, "303849000", "USA"],
      //     [38.066563, 237.75381, "303849000", "USA"],
      //     [38.063614, 237.75714, "303849000", "USA"],
      //     [38.063557, 237.760796, "303849000", "USA"],
      //     [38.06193, 237.76178, "303849000", "USA"],
      //     [38.063572, 237.75405999999998, "303849000", "USA"],
      //     [38.063423, 237.75315, "303849000", "USA"],
      //     [38.06369, 237.75543, "303849000", "USA"],
      //     [38.051838, 237.67316399999999, "303849000", "USA"],
      //     [38.043182, 237.65345000000002, "303849000", "USA"],
      //     [38.02402, 237.61545999999998, "303849000", "USA"],
      //     [38.00905, 237.59235999999999, "303849000", "USA"],
      //     [38.013138, 237.5976, "303849000", "USA"],
      //     [38.002197, 237.58642600000002, "303849000", "USA"],
      //     [37.964966, 237.557884, "303849000", "USA"],
      //     [37.92027, 237.55347, "303849000", "USA"],
      //     [37.907497, 237.55508, "303849000", "USA"],
      //     [37.89294, 237.56448, "303849000", "USA"],
      //     [37.898407, 237.560844, "303849000", "USA"],
      //     [37.866302, 237.58417500000002, "303849000", "USA"],
      //     [37.86865, 237.58263399999998, "303849000", "USA"],
      //     [37.859978, 237.58658, "303849000", "USA"],
      //     [37.85233, 237.58848999999998, "303849000", "USA"],
      //     [37.84277, 237.58108, "303849000", "USA"],
      //     [37.844772, 237.58401, "303849000", "USA"],
      //     [37.838757, 237.57475, "303849000", "USA"],
      //     [37.82999, 237.556305, "303849000", "USA"],
      //     [37.821266, 237.52204, "303849000", "USA"],
      //     [37.82255, 237.52629000000002, "303849000", "USA"],
      //     [37.784416, 237.42551400000002, "303849000", "USA"],
      //     [37.76922, 237.372894, "303849000", "USA"],
      //     [37.7703, 237.37662, "303849000", "USA"],
      //     [37.76795, 237.35654, "303849000", "USA"],
      //     [37.77672, 237.31685, "303849000", "USA"],
      //     [37.767696, 237.30025, "303849000", "USA"],
      //     [37.773975, 237.283195, "303849000", "USA"],
      //     [37.82037, 237.19952999999998, "303849000", "USA"],
      //     [37.82068, 237.19884000000002, "303849000", "USA"],
      //     [37.818626, 237.20333, "303849000", "USA"],
      //     [37.979134, 236.852, "303849000", "USA"],
      //     [37.980843, 236.85005, "303849000", "USA"],
      //     [37.9764, 236.85696000000002, "303849000", "USA"],
      //     [37.989944, 236.84032000000002, "303849000", "USA"],
      //     [37.9854, 236.845245, "303849000", "USA"],
      //     [38.085094, 236.73221, "303849000", "USA"],
      //     [38.12938, 236.68535, "303849000", "USA"],
      //     [38.15328, 236.66174999999998, "303849000", "USA"],
      //     [32.0179, 235.79343, "367219000", "USA"],
      //     [32.03884, 236.34292, "367219000", "USA"],
      //     [32.047356, 236.51984, "367219000", "USA"],
      //     [32.073772, 237.20862, "367219000", "USA"],
      //     [32.09736, 237.768936, "367219000", "USA"],
      //     [32.09602, 237.82629400000002, "367219000", "USA"],
      //     [30.077868, 265.90926, "368952000", "USA"],
      //     [30.077883, 265.90929, "368952000", "USA"],
      //     [32.865124, 280.034584, "338995000", "USA"],
      //     [32.86505, 280.03459, "338995000", "USA"],
      //     [1.471557, 103.82747, "367221000", "USA"],
      //     [1.471577, 103.82751, "367221000", "USA"],
      //     [14.815703, 120.2884, "369469000", "USA"],
      //     [14.815707, 120.28839, "369469000", "USA"],
      //     [32.66866, 242.87948599999999, "366503000", "USA"],
      //     [32.66863, 242.87950999999998, "366503000", "USA"],
      //     [32.66865, 242.87947, "366503000", "USA"],
      //     [35.496235, 384.150118, "368881000", "USA"],
      //     [35.496235, 384.150118, "368881000", "USA"],
      //     [1.4608, 103.83307, "368882000", "USA"],
      //     [1.460723, 103.833015, "368882000", "USA"],
      //     [47.06845, 235.57238, "367276000", "USA"],
      //     [47.064434, 235.5719, "367276000", "USA"],
      //     [47.1084, 235.57605, "367276000", "USA"],
      //     [47.103718, 235.57583599999998, "367276000", "USA"],
      //     [47.117016, 235.57103, "367276000", "USA"],
      //     [47.11455, 235.57463, "367276000", "USA"],
      //     [47.11807, 235.56517, "367276000", "USA"],
      //     [47.118168, 235.52528, "367276000", "USA"],
      //     [47.11335, 235.51670000000001, "367276000", "USA"],
      //     [47.10642, 235.51542, "367276000", "USA"],
      //     [47.108368, 235.515434, "367276000", "USA"],
      //     [47.0927, 235.52062999999998, "367276000", "USA"],
      //     [47.098366, 235.51755, "367276000", "USA"],
      //     [47.087666, 235.52983, "367276000", "USA"],
      //     [47.087517, 235.53578, "367276000", "USA"],
      //     [47.08965, 235.541466, "367276000", "USA"],
      //     [47.097466, 235.543884, "367276000", "USA"],
      //     [47.12195, 235.53053, "367276000", "USA"],
      //     [47.126633, 235.51245, "367276000", "USA"],
      //     [47.126034, 235.524536, "367276000", "USA"],
      //     [47.126316, 235.508286, "367276000", "USA"],
      //     [47.1151, 235.33092, "367276000", "USA"],
      //     [46.9825, 235.5691, "367276000", "USA"],
      //     [47.115, 235.21044999999998, "367276000", "USA"],
      //     [47.115284, 234.94227, "367276000", "USA"],
      //     [47.1625, 234.91793, "367276000", "USA"],
      //     [47.413982, 234.82647, "367276000", "USA"],
      //     [47.54475, 234.78643, "367276000", "USA"],
      //     [47.5671, 234.78052, "367276000", "USA"],
      //     [47.7022, 234.73762, "367276000", "USA"],
      //     [47.785484, 234.71505000000002, "367276000", "USA"],
      //     [47.845383, 234.69445000000002, "367276000", "USA"],
      //     [47.918365, 234.6726, "367276000", "USA"],
      //     [47.955967, 234.680016, "367276000", "USA"],
      //     [48.174618, 234.80085, "367276000", "USA"],
      //     [48.17125, 234.799, "367276000", "USA"],
      //     [48.23005, 234.83033, "367276000", "USA"],
      //     [48.386566, 234.91943, "367276000", "USA"],
      //     [48.412117, 234.94383, "367276000", "USA"],
      //     [48.447517, 234.97487, "367276000", "USA"],
      //     [48.443783, 234.971634, "367276000", "USA"],
      //     [48.45698, 234.987465, "367276000", "USA"],
      //     [48.476284, 235.01343500000002, "367276000", "USA"],
      //     [48.478767, 235.01857, "367276000", "USA"],
      //     [48.4798, 235.06097, "367276000", "USA"],
      //     [48.47705, 235.26562, "367276000", "USA"],
      //     [48.369717, 235.6115, "367276000", "USA"],
      //     [48.37065, 235.60833, "367276000", "USA"],
      //     [48.35255, 235.670715, "367276000", "USA"],
      //     [48.227333, 236.07114, "367276000", "USA"],
      //     [48.225384, 236.0881, "367276000", "USA"],
      //     [48.22563, 236.20319999999998, "367276000", "USA"],
      //     [48.22795, 236.289986, "367276000", "USA"],
      //     [48.225117, 236.361336, "367276000", "USA"],
      //     [48.225067, 236.367165, "367276000", "USA"],
      //     [48.225468, 236.35559999999998, "367276000", "USA"],
      //     [48.22535, 236.46302, "367276000", "USA"],
      //     [48.220566, 236.52846499999998, "367276000", "USA"],
      //     [48.220367, 236.600716, "367276000", "USA"],
      //     [48.206, 236.790184, "367276000", "USA"],
      //     [48.189117, 237.10561, "367276000", "USA"],
      //     [48.174267, 237.19925, "367276000", "USA"],
      //     [48.175114, 237.194214, "367276000", "USA"],
      //     [48.174545, 237.19762, "367276000", "USA"],
      //     [48.162563, 237.23162000000002, "367276000", "USA"],
      //     [48.152367, 237.24983, "367276000", "USA"],
      //     [48.150333, 237.25185, "367276000", "USA"],
      //     [48.154213, 237.24723, "367276000", "USA"],
      //     [48.1482, 237.25357, "367276000", "USA"],
      //     [48.151546, 237.250725, "367276000", "USA"],
      //     [48.14148, 237.258545, "367276000", "USA"],
      //     [48.120968, 237.25957, "367276000", "USA"],
      //     [48.1061, 237.25785000000002, "367276000", "USA"],
      //     [48.100513, 237.25465400000002, "367276000", "USA"],
      //     [48.10261, 237.25592, "367276000", "USA"],
      //     [48.086433, 237.24128000000002, "367276000", "USA"],
      //     [48.07918, 237.23770000000002, "367276000", "USA"],
      //     [48.08045, 237.23783, "367276000", "USA"],
      //     [48.074547, 237.237885, "367276000", "USA"],
      //     [48.071896, 237.23995, "367276000", "USA"],
      //     [48.07265, 237.24782, "367276000", "USA"],
      //     [48.075665, 237.25153, "367276000", "USA"],
      //     [32.705166, 242.81470000000002, "368926485", "USA"],
      //     [32.700848, 242.764435, "368926485", "USA"],
      //     [32.6978, 242.767166, "368926485", "USA"],
      //     [38.6514, 363.1075, "368886000", "USA"],
      //     [38.61807, 363.022217, "368886000", "USA"],
      //     [38.521416, 362.795267, "368886000", "USA"],
      //     [38.484768, 362.698717, "368886000", "USA"],
      //     [38.41955, 362.54135, "368886000", "USA"],
      //     [38.264416, 362.180567, "368886000", "USA"],
      //     [38.244366, 362.126583, "368886000", "USA"],
      //     [38.222233, 362.059633, "368886000", "USA"],
      //     [38.1556, 361.8841, "368886000", "USA"],
      //     [38.14142, 361.836833, "368886000", "USA"],
      //     [38.10813, 361.7373, "368886000", "USA"],
      //     [38.050865, 361.6237, "368886000", "USA"],
      //     [37.99447, 361.521917, "368886000", "USA"],
      //     [37.71958, 360.8606, "368886000", "USA"],
      //     [37.63425, 360.6473, "368886000", "USA"],
      //     [37.635166, 360.649417, "368886000", "USA"],
      //     [37.52698, 360.399267, "368886000", "USA"],
      //     [37.47615, 360.287183, "368886000", "USA"],
      //     [37.4147, 360.1369, "368886000", "USA"],
      //     [37.376682, 360.03605, "368886000", "USA"],
      //     [37.2859, 359.81265, "368886000", "USA"],
      //     [37.2772, 359.782967, "368886000", "USA"],
      //     [37.25225, 359.6797, "368886000", "USA"],
      //     [37.241966, 359.645683, "368886000", "USA"],
      //     [37.1742, 359.515517, "368886000", "USA"],
      //     [37.112984, 359.405133, "368886000", "USA"],
      //     [37.093315, 359.3639, "368886000", "USA"],
      //     [37.04435, 359.24785, "368886000", "USA"],
      //     [36.96495, 359.050167, "368886000", "USA"],
      //     [36.913418, 358.9332, "368886000", "USA"],
      //     [36.734333, 358.505983, "368886000", "USA"],
      //     [36.61895, 358.236017, "368886000", "USA"],
      //     [36.5811, 358.144783, "368886000", "USA"],
      //     [36.57408, 358.113633, "368886000", "USA"],
      //     [36.513218, 357.964183, "368886000", "USA"],
      //     [36.473667, 357.876117, "368886000", "USA"],
      //     [36.4755, 357.879733, "368886000", "USA"],
      //     [36.438217, 357.816633, "368886000", "USA"],
      //     [36.435516, 357.805367, "368886000", "USA"],
      //     [36.43928, 357.581783, "368886000", "USA"],
      //     [36.452667, 357.382067, "368886000", "USA"],
      //     [13.159805, 100.88434, "369970428", "USA"],
      //     [13.163652, 100.88028, "369970428", "USA"],
      //     [13.149127, 100.861565, "369970428", "USA"],
      //     [13.116487, 100.840294, "369970428", "USA"],
      //     [13.076643, 100.82073, "369970428", "USA"],
      //     [13.072928, 100.81491, "369970428", "USA"],
      //     [13.062258, 100.79554, "369970428", "USA"],
      //     [13.057363, 100.77673, "369970428", "USA"],
      //     [13.052155, 100.72444, "369970428", "USA"],
      //     [13.023267, 100.61641, "369970428", "USA"],
      //     [13.017632, 100.60619, "369970428", "USA"],
      //     [12.978615, 100.58275, "369970428", "USA"],
      //     [12.958548, 100.58228, "369970428", "USA"],
      //     [12.919937, 100.58442, "369970428", "USA"],
      //     [12.973388, 100.58262, "369970428", "USA"],
      //     [12.836713, 100.585884, "369970428", "USA"],
      //     [12.728355, 100.58452, "369970428", "USA"],
      //     [12.708535, 100.58333, "369970428", "USA"],
      //     [12.612082, 100.58402, "369970428", "USA"],
      //     [12.577107, 100.587685, "369970428", "USA"],
      //     [12.336107, 100.666466, "369970428", "USA"],
      //     [12.224617, 100.716545, "369970428", "USA"],
      //     [12.064957, 100.780014, "369970428", "USA"],
      //     [12.004622, 100.79536, "369970428", "USA"],
      //     [11.922483, 100.81847, "369970428", "USA"],
      //     [11.718222, 100.9059, "369970428", "USA"],
      //     [58.464684, 379.78152, "367862000", "USA"],
      //     [58.48181, 379.780865, "367862000", "USA"],
      //     [58.489124, 379.775112, "367862000", "USA"],
      //     [58.49126, 379.767178, "367862000", "USA"],
      //     [58.49075, 379.757364, "367862000", "USA"],
      //     [58.473053, 379.747944, "367862000", "USA"],
      //     [58.383617, 379.75172, "367862000", "USA"],
      //     [58.306416, 379.750177, "367862000", "USA"],
      //     [58.25779, 379.754421, "367862000", "USA"],
      //     [58.267353, 379.781313, "367862000", "USA"],
      //     [58.344696, 379.792036, "367862000", "USA"],
      //     [58.41939, 379.798628, "367862000", "USA"],
      //     [58.423347, 379.816984, "367862000", "USA"],
      //     [58.408333, 379.827227, "367862000", "USA"],
      //     [58.320633, 379.820778, "367862000", "USA"],
      //     [58.277145, 379.814915, "367862000", "USA"],
      //     [58.28637, 379.796497, "367862000", "USA"],
      //     [58.384907, 379.78503, "367862000", "USA"],
      //     [58.486107, 379.765022, "367862000", "USA"],
      //     [58.499104, 379.765747, "367862000", "USA"],
      //     [58.50198, 379.7718, "367862000", "USA"],
      //     [58.50379, 379.833927, "367862000", "USA"],
      //     [58.460842, 379.83536, "367862000", "USA"],
      //     [58.495914, 379.844526, "367862000", "USA"],
      //     [58.397667, 379.818043, "367862000", "USA"],
      //     [58.251614, 379.770718, "367862000", "USA"],
      //     [58.254704, 379.801157, "367862000", "USA"],
      //     [58.329296, 379.8131, "367862000", "USA"],
      //     [58.39102, 379.817923, "367862000", "USA"],
      //     [58.463364, 379.819824, "367862000", "USA"],
      //     [58.4715, 379.797354, "367862000", "USA"],
      //     [58.46787, 379.79143, "367862000", "USA"],
      //     [58.35916, 379.79605, "367862000", "USA"],
      //     [58.289913, 379.793253, "367862000", "USA"],
      //     [58.262897, 379.79789, "367862000", "USA"],
      //     [58.261242, 379.808084, "367862000", "USA"],
      //     [58.279377, 379.82498, "367862000", "USA"],
      //     [58.321774, 379.83043, "367862000", "USA"],
      //     [58.377174, 379.834194, "367862000", "USA"],
      //     [58.46937, 379.83335999999997, "367862000", "USA"],
      //     [58.48261, 379.800999, "367862000", "USA"],
      //     [58.362694, 379.803349, "367862000", "USA"],
      //     [58.297188, 379.800255, "367862000", "USA"],
      //     [58.265358, 379.772423, "367862000", "USA"],
      //     [58.333427, 379.78005, "367862000", "USA"],
      //     [58.376842, 379.78276, "367862000", "USA"],
      //     [58.41547, 379.782917, "367862000", "USA"],
      //     [58.483707, 379.780348, "367862000", "USA"],
      //     [58.49185, 379.785698, "367862000", "USA"],
      //     [58.492825, 379.789165, "367862000", "USA"],
      //     [58.464973, 379.80383, "367862000", "USA"],
      //     [58.356537, 379.786861, "367862000", "USA"],
      //     [58.25643, 379.765574, "367862000", "USA"],
      //     [58.247414, 379.773664, "367862000", "USA"],
      //     [58.251865, 379.796696, "367862000", "USA"],
      //     [58.31393, 379.806458, "367862000", "USA"],
      //     [58.393124, 379.80543, "367862000", "USA"],
      //     [58.44905, 379.800173, "367862000", "USA"],
      //     [58.493793, 379.793734, "367862000", "USA"],
      //     [58.48594, 379.764153, "367862000", "USA"],
      //     [58.385357, 379.762598, "367862000", "USA"],
      //     [58.334957, 379.75881, "367862000", "USA"],
      //     [58.284676, 379.752703, "367862000", "USA"],
      //     [58.25358, 379.764334, "367862000", "USA"],
      //     [58.254154, 379.768494, "367862000", "USA"],
      //     [58.26022, 379.777008, "367862000", "USA"],
      //     [58.265427, 379.777971, "367862000", "USA"],
      //     [58.311714, 379.775898, "367862000", "USA"],
      //     [58.385376, 379.768578, "367862000", "USA"],
      //     [58.445107, 379.758923, "367862000", "USA"],
      //     [58.482914, 379.761421, "367862000", "USA"],
      //     [58.414158, 379.772348, "367862000", "USA"],
      //     [58.34977, 379.763832, "367862000", "USA"],
      //     [58.298542, 379.753893, "367862000", "USA"],
      //     [58.25769, 379.753548, "367862000", "USA"],
      //     [58.260807, 379.77156, "367862000", "USA"],
      //     [58.308617, 379.778719, "367862000", "USA"],
      //     [58.348316, 379.782797, "367862000", "USA"],
      //     [58.396027, 379.785154, "367862000", "USA"],
      //     [58.475807, 379.78498, "367862000", "USA"],
      //     [58.476894, 379.78493100000003, "367862000", "USA"],
      //     [58.472637, 379.78495, "367862000", "USA"],
      //     [58.4825, 379.782846, "367862000", "USA"],
      //     [58.458057, 379.75369, "367862000", "USA"],
      //     [58.397038, 379.75827400000003, "367862000", "USA"],
      //     [58.31893, 379.757856, "367862000", "USA"],
      //     [58.28037, 379.736797, "367862000", "USA"],
      //     [58.28567, 379.72885, "367862000", "USA"],
      //     [58.291973, 379.728748, "367862000", "USA"],
      //     [58.38362, 379.73497, "367862000", "USA"],
      //     [58.44797, 379.735188, "367862000", "USA"],
      //     [58.45067, 379.73837, "367862000", "USA"],
      //     [58.451183, 379.754602, "367862000", "USA"],
      //     [58.408257, 379.762684, "367862000", "USA"],
      //     [58.35256, 379.764774, "367862000", "USA"],
      //     [58.31978, 379.758984, "367862000", "USA"],
      //     [58.318012, 379.744074, "367862000", "USA"],
      //     [58.321045, 379.736372, "367862000", "USA"],
      //     [58.33907, 379.73424, "367862000", "USA"],
      //     [58.448242, 379.739744, "367862000", "USA"],
      //     [58.45104, 379.742718, "367862000", "USA"],
      //     [58.45248, 379.758455, "367862000", "USA"],
      //     [58.436394, 379.768084, "367862000", "USA"],
      //     [58.34107, 379.768967, "367862000", "USA"],
      //     [58.254986, 379.766027, "367862000", "USA"],
      //     [36.882477, 283.66044999999997, "367863000", "USA"],
      //     [36.882423, 283.660446, "367863000", "USA"],
      //     [30.680372, 271.96567500000003, "369855000", "USA"],
      //     [30.680351, 271.96569, "369855000", "USA"],
      //     [13.428762, 144.66353, "367218000", "USA"],
      //     [13.42873, 144.6635, "367218000", "USA"],
      //     [13.428785, 144.66353, "367218000", "USA"],
      //     [1.4641, 103.834206, "367867000", "USA"],
      //     [1.464113, 103.8342, "367867000", "USA"],
      //     [23.594717, 132.09251, "369854000", "USA"],
      //     [23.60405, 132.13397, "369854000", "USA"],
      //     [23.646008, 132.39163, "369854000", "USA"],
      //     [23.610243, 132.17097, "369854000", "USA"],
      //     [23.722113, 132.83885, "369854000", "USA"],
      //     [23.740973, 132.9181, "369854000", "USA"],
      //     [23.771023, 132.99907, "369854000", "USA"],
      //     [23.860516, 133.0484, "369854000", "USA"],
      //     [24.223553, 133.2566, "369854000", "USA"],
      //     [24.394064, 133.34624, "369854000", "USA"],
      //     [25.22268, 133.78883, "369854000", "USA"],
      //     [25.41881, 133.88553, "369854000", "USA"],
      //     [25.762285, 134.0498, "369854000", "USA"],
      //     [25.843658, 134.09492, "369854000", "USA"],
      //     [25.941872, 134.15398, "369854000", "USA"],
      //     [26.13832, 134.25946, "369854000", "USA"],
      //     [26.344112, 134.37207, "369854000", "USA"],
      //     [26.583605, 134.49564, "369854000", "USA"],
      //     [26.667465, 134.53526, "369854000", "USA"],
      //     [26.751062, 134.57748, "369854000", "USA"],
      //     [26.871471, 134.64464, "369854000", "USA"],
      //     [27.231382, 134.84004, "369854000", "USA"],
      //     [27.566622, 135.01195, "369854000", "USA"],
      //     [13.362017, 144.9375, "369989000", "USA"],
      //     [13.315983, 144.9088, "369989000", "USA"],
      //     [13.2723, 144.87589, "369989000", "USA"],
      //     [13.4156, 144.96233, "369989000", "USA"],
      //     [13.418267, 144.9643, "369989000", "USA"],
      //     [13.426233, 144.97812, "369989000", "USA"],
      //     [13.23635, 144.84435, "369989000", "USA"],
      //     [13.23085, 144.8454, "369989000", "USA"],
      //     [13.2306, 144.84862, "369989000", "USA"],
      //     [13.23455, 144.85164, "369989000", "USA"],
      //     [13.262217, 144.86537, "369989000", "USA"],
      //     [13.322683, 144.90025, "369989000", "USA"],
      //     [13.384983, 144.94844, "369989000", "USA"],
      //     [13.44065, 144.99432, "369989000", "USA"],
      //     [13.30905, 144.8934, "369989000", "USA"],
      //     [13.299317, 144.88744, "369989000", "USA"],
      //     [13.448883, 144.99313, "369989000", "USA"],
      //     [13.455417, 145.00566, "369989000", "USA"],
      //     [13.451983, 145.0065, "369989000", "USA"],
      //     [13.447833, 145.00462, "369989000", "USA"],
      //     [13.4158, 144.98193, "369989000", "USA"],
      //     [13.45075, 145.00615, "369989000", "USA"],
      //     [13.341283, 144.9314, "369989000", "USA"],
      //     [13.2429, 144.86754, "369989000", "USA"],
      //     [13.239417, 144.86253, "369989000", "USA"],
      //     [13.239317, 144.85918, "369989000", "USA"],
      //     [13.250233, 144.85565, "369989000", "USA"],
      //     [13.330683, 144.91573, "369989000", "USA"],
      //     [13.462483, 145.00455, "369989000", "USA"],
      //     [13.463267, 145.0004, "369989000", "USA"],
      //     [13.435617, 144.97832, "369989000", "USA"],
      //     [13.360283, 144.92757, "369989000", "USA"],
      //     [13.242733, 144.84528, "369989000", "USA"],
      //     [13.2387, 144.8445, "369989000", "USA"],
      //     [13.2362, 144.84639, "369989000", "USA"],
      //     [13.236483, 144.85, "369989000", "USA"],
      //     [13.239967, 144.8535, "369989000", "USA"],
      //     [13.275117, 144.87402, "369989000", "USA"],
      //     [13.2406, 144.84445, "369989000", "USA"],
      //     [13.2934, 144.88469, "369989000", "USA"],
      //     [13.324083, 144.90248, "369989000", "USA"],
      //     [13.402467, 144.9591, "369989000", "USA"],
      //     [13.4541, 145.00531, "369989000", "USA"],
      //     [13.453983, 145.01259, "369989000", "USA"],
      //     [13.45005, 145.01254, "369989000", "USA"],
      //     [13.410983, 144.98058, "369989000", "USA"],
      //     [13.4028, 144.95938, "369989000", "USA"],
      //     [13.295367, 144.8889, "369989000", "USA"],
      //     [13.3718, 144.93518, "369989000", "USA"],
      //     [13.42775, 144.98427, "369989000", "USA"],
      //     [13.2725, 144.8661, "369989000", "USA"],
      //     [13.2661, 144.87263, "369989000", "USA"],
      //     [13.277067, 144.87999, "369989000", "USA"],
      //     [29.472073, 211.26758, "369499000", "USA"],
      //     [29.57036, 211.37839, "369499000", "USA"],
      //     [29.573563, 211.38426, "369499000", "USA"],
      //     [29.574577, 211.3941, "369499000", "USA"],
      //     [29.571592, 211.4017, "369499000", "USA"],
      //     [29.565878, 211.40923, "369499000", "USA"],
      //     [29.566038, 211.41693, "369499000", "USA"],
      //     [29.569078, 211.42159, "369499000", "USA"],
      //     [29.576674, 211.42552, "369499000", "USA"],
      //     [29.598679, 211.45247, "369499000", "USA"],
      //     [29.602043, 211.46864, "369499000", "USA"],
      //     [29.60836, 211.4785, "369499000", "USA"],
      //     [29.710169, 211.58644, "369499000", "USA"],
      //     [29.73259, 211.61115, "369499000", "USA"],
      //     [29.697237, 211.57272, "369499000", "USA"],
      //     [29.763788, 211.64467, "369499000", "USA"],
      //     [29.815796, 211.69235, "369499000", "USA"],
      //     [29.611666, 211.48222, "369499000", "USA"],
      //     [29.819565, 211.69577, "369499000", "USA"],
      //     [29.606869, 211.47684, "369499000", "USA"],
      //     [29.820534, 211.69667, "369499000", "USA"],
      //     [29.890589, 211.76305, "369499000", "USA"],
      //     [29.65149, 211.52467, "369499000", "USA"],
      //     [29.892391, 211.76521, "369499000", "USA"],
      //     [30.144016, 212.05856, "369499000", "USA"],
      //     [30.54615, 212.5074, "369499000", "USA"],
      //     [30.628578, 212.60556, "369499000", "USA"],
      //     [30.743177, 212.73137, "369499000", "USA"],
      //     [30.824753, 212.83057, "369499000", "USA"],
      //     [30.913507, 212.92741, "369499000", "USA"],
      //     [30.962538, 212.98708, "369499000", "USA"],
      //     [30.90943, 212.92299, "369499000", "USA"],
      //     [31.065289, 213.11162, "369499000", "USA"],
      //     [30.998173, 213.0299, "369499000", "USA"],
      //     [31.322037, 213.41252, "369499000", "USA"],
      //     [31.35081, 213.44403, "369499000", "USA"],
      //     [30.76874, 212.7627, "369499000", "USA"],
      //     [31.755114, 213.89755, "369499000", "USA"],
      //     [31.944717, 214.1105, "369499000", "USA"],
      //     [32.237656, 214.43646, "369499000", "USA"],
      //     [32.425926, 214.65242, "369499000", "USA"],
      //     [32.678772, 214.94775, "369499000", "USA"],
      //     [32.9695, 215.26942, "369499000", "USA"],
      //     [33.252853, 215.60652, "369499000", "USA"],
      //     [33.452946, 215.83936, "369499000", "USA"],
      //     [33.87064, 216.33966, "369499000", "USA"],
      //     [36.950497, 283.6699, "367852000", "USA"],
      //     [36.95046, 283.66991, "367852000", "USA"],
      //     [30.400557, 278.47576, "338806000", "USA"],
      //     [30.40053, 278.47576, "338806000", "USA"],
      //     [13.424103, 144.67155, "338927000", "USA"],
      //     [13.424082, 144.67157, "338927000", "USA"],
      //     [41.49142, 378.402285, "367866000", "USA"],
      //     [41.402245, 378.395542, "367866000", "USA"],
      //     [41.3956, 378.385653, "367866000", "USA"],
      //     [41.39887, 378.377232, "367866000", "USA"],
      //     [41.402798, 378.374578, "367866000", "USA"],
      //     [41.581673, 378.376087, "367866000", "USA"],
      //     [41.65965, 378.380125, "367866000", "USA"],
      //     [41.6613, 378.392363, "367866000", "USA"],
      //     [41.604626, 378.39446, "367866000", "USA"],
      //     [41.466877, 378.393812, "367866000", "USA"],
      //     [41.50252, 378.393747, "367866000", "USA"],
      //     [41.374104, 378.395864, "367866000", "USA"],
      //     [41.370743, 378.392893, "367866000", "USA"],
      //     [41.38642, 378.383516, "367866000", "USA"],
      //     [41.513836, 378.392632, "367866000", "USA"],
      //     [41.639454, 378.3983, "367866000", "USA"],
      //     [41.65625, 378.38587, "367866000", "USA"],
      //     [41.647987, 378.374092, "367866000", "USA"],
      //     [41.562004, 378.376095, "367866000", "USA"],
      //     [41.407154, 378.373787, "367866000", "USA"],
      //     [41.37686, 378.379827, "367866000", "USA"],
      //     [41.381454, 378.389809, "367866000", "USA"],
      //     [41.426136, 378.392624, "367866000", "USA"],
      //     [41.51876, 378.40545, "367866000", "USA"],
      //     [41.656, 378.41936499999997, "367866000", "USA"],
      //     [41.654167, 378.4046, "367866000", "USA"],
      //     [41.644382, 378.397709, "367866000", "USA"],
      //     [41.55795, 378.394358, "367866000", "USA"],
      //     [41.41632, 378.383339, "367866000", "USA"],
      //     [41.383156, 378.379957, "367866000", "USA"],
      //     [41.378597, 378.377272, "367866000", "USA"],
      //     [41.37688, 378.37323200000003, "367866000", "USA"],
      //     [41.377083, 378.368788, "367866000", "USA"],
      //     [41.38019, 378.364582, "367866000", "USA"],
      //     [41.378265, 378.366325, "367866000", "USA"],
      //     [41.39083, 378.363642, "367866000", "USA"],
      //     [41.553535, 378.355719, "367866000", "USA"],
      //     [41.65224, 378.356075, "367866000", "USA"],
      //     [41.655926, 378.365738, "367866000", "USA"],
      //     [41.65483, 378.36703, "367866000", "USA"],
      //     [41.656292, 378.36499200000003, "367866000", "USA"],
      //     [41.65483, 378.36703, "367866000", "USA"],
      //     [41.645542, 378.368006, "367866000", "USA"],
      //     [41.611347, 378.367579, "367866000", "USA"],
      //     [41.616856, 378.367615, "367866000", "USA"],
      //     [41.516537, 378.363708, "367866000", "USA"],
      //     [41.391888, 378.352112, "367866000", "USA"],
      //     [41.381676, 378.354574, "367866000", "USA"],
      //     [41.37795, 378.367338, "367866000", "USA"],
      //     [41.378418, 378.370235, "367866000", "USA"],
      //     [41.387512, 378.381004, "367866000", "USA"],
      //     [41.550297, 378.382338, "367866000", "USA"],
      //     [41.624405, 378.384623, "367866000", "USA"],
      //     [41.63075, 378.383696, "367866000", "USA"],
      //     [41.635223, 378.378862, "367866000", "USA"],
      //     [41.625637, 378.362062, "367866000", "USA"],
      //     [41.468113, 378.363625, "367866000", "USA"],
      //     [41.47253, 378.363592, "367866000", "USA"],
      //     [41.388344, 378.36612, "367866000", "USA"],
      //     [41.38347, 378.367678, "367866000", "USA"],
      //     [41.378635, 378.373066, "367866000", "USA"],
      //     [41.379078, 378.389072, "367866000", "USA"],
      //     [41.377846, 378.386465, "367866000", "USA"],
      //     [41.382095, 378.392313, "367866000", "USA"],
      //     [41.38847, 378.393925, "367866000", "USA"],
      //     [41.452324, 378.399761, "367866000", "USA"],
      //     [41.569912, 378.404432, "367866000", "USA"],
      //     [41.57628, 378.408787, "367866000", "USA"],
      //     [41.576126, 378.411558, "367866000", "USA"],
      //     [41.57468, 378.413954, "367866000", "USA"],
      //     [41.56989, 378.414833, "367866000", "USA"],
      //     [41.386806, 378.416763, "367866000", "USA"],
      //     [41.373684, 378.415619, "367866000", "USA"],
      //     [41.376057, 378.416492, "367866000", "USA"],
      //     [41.365463, 378.406137, "367866000", "USA"],
      //     [41.367447, 378.410002, "367866000", "USA"],
      //     [41.36395, 378.388533, "367866000", "USA"],
      //     [41.371, 378.376324, "367866000", "USA"],
      //     [41.380775, 378.372227, "367866000", "USA"],
      //     [41.440777, 378.376368, "367866000", "USA"],
      //     [36.945675, 283.667046, "367932000", "USA"],
      //     [36.94568, 283.66714, "367932000", "USA"],
      //     [-33.86546, 151.22736, "503172000", "AUS"],
      //     [-33.865463, 151.22783, "503172000", "AUS"],
      //     [-33.866653, 151.22734, "503172000", "AUS"],
      //     [-33.86461, 151.22704, "503172000", "AUS"],
      //   ],
      //   type9: [
      //     [-32.17555, 115.680916, "503115000", "AUS"],
      //     [-32.181953, 115.69452, "503115000", "AUS"],
      //     [-32.178757, 115.68726, "503115000", "AUS"],
      //     [-32.183968, 115.708206, "503115000", "AUS"],
      //     [-32.18908, 115.716156, "503115000", "AUS"],
      //     [-32.186436, 115.71518, "503115000", "AUS"],
      //     [-32.199646, 115.71526, "503115000", "AUS"],
      //     [-32.203083, 115.71937, "503115000", "AUS"],
      //     [-32.202015, 115.7336, "503115000", "AUS"],
      //     [-32.202698, 115.72892, "503115000", "AUS"],
      //     [-32.202854, 115.73907, "503115000", "AUS"],
      //     [-32.20573, 115.75272, "503115000", "AUS"],
      //     [-32.196716, 115.76283, "503115000", "AUS"],
      //     [-32.188118, 115.76659, "503115000", "AUS"],
      //     [-32.191666, 115.765, "503115000", "AUS"],
      //     [-32.184975, 115.76727, "503115000", "AUS"],
      //     [-32.171566, 115.76247, "503115000", "AUS"],
      //     [-32.158527, 115.76182, "503115000", "AUS"],
      //     [-32.15934, 115.76542, "503115000", "AUS"],
      //     [-32.15881, 115.76156, "503115000", "AUS"],
      //     [24.977512, 55.050724, "367846000", "USA"],
      //     [24.977516, 55.050728, "367846000", "USA"],
      //     [36.9336, 283.667725, "367585850", "USA"],
      //     [36.933582, 283.667755, "367585850", "USA"],
      //     [21.311604, 202.1299, "369202000", "USA"],
      //     [21.311577, 202.12987, "369202000", "USA"],
      //     [33.159912, 129.71231, "431999573", "JPN"],
      //     [33.156628, 129.7137, "431999573", "JPN"],
      //     [33.152412, 129.70973, "431999573", "JPN"],
      //     [33.124702, 129.71408, "431999573", "JPN"],
      //     [33.10901, 129.71356, "431999573", "JPN"],
      //     [33.10339, 129.67377, "431999573", "JPN"],
      //     [33.098324, 129.66115, "431999573", "JPN"],
      //     [41.089825, 141.01077, "431999575", "JPN"],
      //     [41.08693, 140.99677, "431999575", "JPN"],
      //     [41.06268, 140.79655, "431999575", "JPN"],
      //     [41.06211, 140.79109, "431999575", "JPN"],
      //     [41.064186, 140.78632, "431999575", "JPN"],
      //     [41.0881, 140.74985, "431999575", "JPN"],
      //     [41.106262, 140.72961, "431999575", "JPN"],
      //     [41.151035, 140.72299, "431999575", "JPN"],
      //     [41.13987, 140.72444, "431999575", "JPN"],
      //     [41.175632, 140.72047, "431999575", "JPN"],
      //     [41.247833, 140.71484, "431999575", "JPN"],
      //     [41.256542, 140.7075, "431999575", "JPN"],
      //     [41.28022, 140.64517, "431999575", "JPN"],
      //     [41.300804, 140.42822, "431999575", "JPN"],
      //     [41.306133, 140.41443, "431999575", "JPN"],
      //     [41.312317, 140.37401, "431999575", "JPN"],
      //     [41.32292, 140.33702, "431999575", "JPN"],
      //     [41.33289, 140.27122, "431999575", "JPN"],
      //     [41.32573, 140.0772, "431999575", "JPN"],
      //     [41.32784, 140.08446, "431999575", "JPN"],
      //     [41.336166, 140.25734, "431999575", "JPN"],
      //     [41.358036, 140.34999, "431999575", "JPN"],
      //     [41.360683, 140.37341, "431999575", "JPN"],
      //     [41.348057, 140.43361, "431999575", "JPN"],
      //     [41.33053, 140.49458, "431999575", "JPN"],
      //     [41.31079, 140.54802, "431999575", "JPN"],
      //     [41.317333, 140.53201, "431999575", "JPN"],
      //     [41.245037, 140.701, "431999575", "JPN"],
      //     [41.22681, 140.70886, "431999575", "JPN"],
      //     [41.20851, 140.71123, "431999575", "JPN"],
      //     [41.158142, 140.72363, "431999575", "JPN"],
      //     [41.137157, 140.72679, "431999575", "JPN"],
      //     [41.1073, 140.73679, "431999575", "JPN"],
      //     [41.085377, 140.75674, "431999575", "JPN"],
      //     [41.089344, 140.7506, "431999575", "JPN"],
      //     [41.07101, 140.77916, "431999575", "JPN"],
      //     [41.066753, 140.8088, "431999575", "JPN"],
      //     [41.07614, 140.87871, "431999575", "JPN"],
      //     [41.08291, 140.94911, "431999575", "JPN"],
      //     [41.09722, 141.04872, "431999575", "JPN"],
      //   ],
      //   type10: [
      //     [26.91682, 284.8388, "367212000", "USA"],
      //     [26.651546, 284.83376, "367212000", "USA"],
      //     [26.236683, 284.83042, "367212000", "USA"],
      //     [25.931618, 284.84058, "367212000", "USA"],
      //     [25.790154, 284.825966, "367212000", "USA"],
      //     [25.649176, 284.82599, "367212000", "USA"],
      //     [25.403267, 284.83495, "367212000", "USA"],
      //     [25.245598, 284.832954, "367212000", "USA"],
      //     [25.036793, 284.83751, "367212000", "USA"],
      //     [24.768806, 284.832245, "367212000", "USA"],
      //     [24.605083, 284.83169599999997, "367212000", "USA"],
      //     [24.435246, 284.876366, "367212000", "USA"],
      //     [24.210297, 285.06208, "367212000", "USA"],
      //     [24.134329, 285.13394, "367212000", "USA"],
      //     [24.124819, 285.14086, "367212000", "USA"],
      //     [24.06925, 285.15675, "367212000", "USA"],
      //     [23.931807, 285.23563, "367212000", "USA"],
      //     [23.861643, 285.28895, "367212000", "USA"],
      //     [23.713684, 285.3097, "367212000", "USA"],
      //     [23.134039, 285.318886, "367212000", "USA"],
      //     [22.986668, 285.315445, "367212000", "USA"],
      //     [22.822662, 285.31959, "367212000", "USA"],
      //     [22.696913, 285.31494, "367212000", "USA"],
      //     [22.517653, 285.31475, "367212000", "USA"],
      //     [22.324932, 285.30826, "367212000", "USA"],
      //     [22.164326, 285.300934, "367212000", "USA"],
      //     [32.715984, 242.825165, "303866000", "USA"],
      //     [32.715984, 242.82523, "303866000", "USA"],
      //     [13.75943, 113.77608, "367837000", "USA"],
      //     [1.308227, 103.645676, "338823000", "USA"],
      //     [1.308215, 103.645676, "338823000", "USA"],
      //     [35.46594, 139.64955, "367836000", "USA"],
      //     [35.46594, 139.64955, "367836000", "USA"],
      //   ],
      //   type11: [],
      // },

      //scope: [1,2,3,4,5,6,7,8]
    };
  },
  mounted() {
    this.list = new Array(12).fill(true);
    this.listScope = new Array(8).fill(true);
  },
  methods: {
    updateValue(value, i) {
      this.list[i] = value[0];
      for (let i = 1; i < 9; i++) {
        if (this.scope.indexOf(i) == -1) {
          this.listScope[i - 1] = false;
        }
      }
      this.$emit("updateValue", this.list, this.listScope);
    },
    closeCop() {
      this.$emit("closeCop");
    },
    updateValueScope(value, i) {
      this.listScope[i] = value[0];

      for (let i = 1; i < 9; i++) {
        if (this.scope.indexOf(i) == -1) {
          this.listScope[i - 1] = false;
        }
      }

      console.log("666");
      console.log(this.scope);
      console.log(this.listScope);

      this.$emit("updateValue", this.list, this.listScope);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onCancele() {},
  },
};
</script>

<style scoped lang="less">
.toolbar-right {
  width: 300px;
  box-shadow: -2px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray, 2px 0 3px -1px gray;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  // left: 0;
  z-index: 9200;
  font-size: 16pt;
  border-radius: 3px;
  .toolbar-line {
    font-size: 12pt;
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    color: mistyrose;
  }
}

/deep/.el-tabs__nav {
  background-color: white;
}
.close-button {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 9277;
    font-size: 25px;
    color: #fff;
  }

.inside {
  max-height: 150px;
  overflow-y: auto;
  padding-right: 6px;
}
.inside::-webkit-scrollbar {
      //设置滚动条宽高

      width: 7px;

      height: 12px;
    }

.inside::-webkit-scrollbar-thumb {
      //滚动条样式

      border-radius: 5px;

      -webkit-box-shadow: inset 0 0 5px #242b56;

      background: #242b56;
    }


</style>
