<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "fileSelect",
  props: {},
  data() {
    return {
      value: true,
      resultData: null,
      fileData: [],
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="less">

</style>
