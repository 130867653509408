<template>
  <div>
    <div style="height: 100%" ref="Screen">
      <div class="left" id="viz" ref="viz"></div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import I18n from "vue-i18n";
import * as NeoVis from "neovis.js";
// import NeoVis from 'https://unpkg.com/neovis.js@2.0.2'

export default {
  props: ["cypherData", "serverFlag"],
  components: {},
  data() {
    return {
      relationList: [
        "departed",
        "returned",
        "Returned",
        "operations",
        "deployment",
        "certification",
        "sea_trials",
        "biennial_multinational_exercise",
        "moored",
        "PHOTOEX",
        "transited",
        "transiting",
        "underway",
        "vist",
        "port_visit",
        "visit",
        "pulled",
        "onload_ammunition",
        "Inport",
        "replenishment_at_sea",
        "locate_in",
        "arrived",
        "stop",
        "port_call",
        "Port_call",
        "transited_westbound",
        "undocked",
        "commissioned",
        "transit",
        "multi_national_exercise",
        "route",
        "inport",
        "RIMPAC",
        "overhaul",
        "trials",
        "emergent_repairs",
        "EOH",
        "Drydocking_Selected_Restricted_Availability",
        "entered",
        "liberty_port_call",
        "Port_calls",
        "ORSE",
        "local_operations",
        "TWC",
        "Joint_Task_Force_Exercise",
        "SRA",
        "liberty_port_visit",
        "ARG",
        "exercise",
        "PCO_operations",
        "TRE",
        "Port",
        "Port_visit",
        "departed_Pearl_Harbor",
        "Cruise",
        "anchored",
        "Tactical_Evolution_Exercise",
        "repairs",
        "liberty_visit",
        "port",
        "testing",
        "routine_training",
        "Arrived",
        "upkeep",
        "Underway",
        "moved",
        "sortied",
        "approaching_Hurricane",
        "PSA",
        "Departed",
        "Transited",
        "acoustic_trials",
        "MK_ADCAP_exercise",
        "undocking",
        "onload_ammo",
        "Final_Evaluation_Problem_FEP",
        "TSTA",
        "FRS",
        "homeport_change",
        "training",
        "undocked_and_moored",
        "Upkeep",
        "ASW",
        "PASSEX",
        "FEP",
        "conducted_CQ",
        "JTFEX",
        "carrier_qualifications",
        "FRS_CQ",
        "Quarterly_Surge_Sustainment_training",
        "readiness_training",
        "CQ",
        "sustainment_training",
        "anti_submarine_warfare_exercise",
        "deployed",
        "redeploy",
        "Entered",
        "Anchored",
        "relieved",
        "DSRA",
        "exercise_Valiant_Shield_",
        "parade",
        "exercise_Inspired_Alert",
        "Selected_Restricted_Availability_SRA_period",
        "Undocked_and_moored",
        "conducted_patrols",
        "multilateral_exercise",
        "routine_carrier_operations",
        "military_operations",
        "trilateral_exercise",
        "biennial_field_training_exercise",
        "ANNUALEX",
        "flight_operations_demonstration",
        "remain",
        "biennial_joint_exercise",
        "stationed",
        "Exercise",
        "exercise_Rim_of_the_Pacific",
        "ASW_exercise",
        "conducting_an_ASW_exercise",
        "Sea_and_Air_Parade",
        "passing_exercise",
        "exercise_Malabar",
        "biennial_exercise",
        "test_Launches",
        "refuel",
        "launched__Tomahawk_Land_Attack_Missiles_TLAMs",
        "anchored_off",
        "christened",
        "transited_southbound",
        "voyage",
        "Moored",
        "photoex",
        "moved_S",
        "transported",
        "fix",
        "launched",
        "resting",
        "offload_ammo",
        "gunnex",
        "qualifications",
        "ceremony",
        "onload",
        "ammunition_onload",
        "photo_exercise",
        "replenishment",
        "offload",
        "rendezvous",
        "comptuex",
      ],
      nodeList: [],
    };
  },
  computed: {
  },
  created() {},
  destroyed() {},
  mounted() {
    this.draw();
  },
  methods: {
    updateData() {
      this.draw();
    },
    draw() {
      let config;
      if (this.serverFlag == "1") {
        config = this.getServerDataConfig();
      } else {
        config = this.getLocalDataConfig();
      }
      let neoViz = new NeoVis.default(config);
      neoViz.render();
      console.log(neoViz);
    },
    getServerDataConfig() {
      const edgesConfig = {
        title: "for",
        label: "for",
        caption: true,
        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
          // function: {
          //   title: (props) => NeoVis.objectToTitleHtml(props),
          // },
          static: {
            color: "grey", // static property is a constant property for all nodes of the type
          },
        },
      };
      let newRelationship = {};
      this.relationList.map((obj, a) => {
        console.log(obj);
        newRelationship[obj + ""] = edgesConfig;
        return obj;
      });
      console.log(JSON.stringify(newRelationship));
      const config = {
        containerId: "viz",
        neo4j: {
          serverUrl: "bolt://101.37.156.17:7687",
          serverUser: "neo4j",
          serverPassword: "CgSuL&%bs3",
        },
        visConfig: {
          nodes: {
            // physics: {
            // enabled: false,
            // },
            shape: "circle",
            // color: "red"
            font: {
              // color: "green",
              background: "none",
              strokeWidth: 0,
              size: 10,
            },
            widthConstraint: { maximum: 50 },
            scaling: {
              min: 10,
              max: 30,
              label: {
                enabled: false,
                min: 14,
                max: 30,
                maxVisible: 30,
                drawThreshold: 5,
              },
              customScalingFunction: function (min, max, total, value) {
                if (max === min) {
                  return 0.5;
                } else {
                  let scale = 1 / (max - min);
                  return Math.max(0, (value - min) * scale);
                }
              },
            },
            shadow: {
              enabled: false,
              color: "rgba(0,0,0,0.5)",
              size: 10,
              x: 5,
              y: 5,
            },
            shape: "ellipse",
            shapeProperties: {
              borderDashes: false, // only for borders
              borderRadius: 6, // only for box shape
              interpolation: false, // only for image and circularImage shapes
              useImageSize: false, // only for image and circularImage shapes
              useBorderWithImage: false, // only for image shape
            },
            value: 1,
          },
          edges: {
            // physics: {
            // enabled: false,
            // },
            arrows: {
              to: {
                enabled: true,

                scaleFactor: 0.5,
                type: "arrow",
              },
            },
            smooth: {
              enabled: true,
              // type: "dynamic",
              // roundness: 0.5
            },
            font: {
              // color: "green",
              // background: "none",
              // strokeWidth: 0,
              size: 8,
            },
          },
        },
        labels: {
          // Area: {
          //     label: "name",
          //     [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
          //         static: {
          //           color: "#ecbe3c", // static property is a constant property for all nodes of the type
          //           shape: "circle",
          //           size: "20"
          //         }
          //     }

          // },
          Area: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
              },
              static: {
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
                // borderWidth: 1,
                // borderWidthSelected: 2,
                color: {
                  // border: "#c0a378",
                  background: "#ecb13c",
                  // highlight: {
                  //   border: "#c0a378",
                  // },
                },
                // widthConstraint:{maximum:50}
              },
            },
          },
          AircraftCarrier: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#165184", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Destroyer: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8bb035", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          SupplyShip: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Cruiser: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          AmphibiousAssaultShip: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Warship: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Submarine: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          LittoralCombatShip: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          DockCarrier: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          MineCountermeasuresVessel: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Ship: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
          Port: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                shape: "circle",
                font: {
                  size: 10,
                  color: "#fff",
                  background: "none",
                },
              },
            },
          },
        },
        relationships: {
          Anchored: {
            title: "for",
            label: "for",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "grey", // static property is a constant property for all nodes of the type
              },
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
              },
            },
          },
          Entered: {
            label: "for",
            caption: true,
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
              },
              static: {
                color: "grey", // static property is a constant property for all nodes of the type
              },
            },
          },
          departed: {
            label: "for",
            caption: true,
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
              },
              static: {
                color: "grey", // static property is a constant property for all nodes of the type
              },
            },
          },
          returned: {
            label: "for",
            caption: true,
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
                label: (props) => NeoVis.objectToTitleHtml(props),
              },
              static: {
                color: "grey", // static property is a constant property for all nodes of the type
              },
            },
          },
          Returned: {
            label: "for",
            caption: true,
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              function: {
                title: (props) => NeoVis.objectToTitleHtml(props),
              },
              static: {
                color: "grey", // static property is a constant property for all nodes of the type
              },
            },
          },
        },
        relationships: newRelationship,
        // initialCypher: "MATCH p=()-[]->() RETURN p LIMIT 25",
        initialCypher: this.cypherData,
      };
      return config;
    },
    getLocalDataConfig() {
      const config = {
        containerId: "viz",
        neo4j: {
          serverUrl: "bolt://localhost:7687",
          serverUser: "neo4j",
          serverPassword: "123456",
        },

        visConfig: {
          layout: {
            randomSeed: undefined,
            improvedLayout: true,
            clusterThreshold: 150,
            hierarchical: {
              enabled: false,
              levelSeparation: 150,
              nodeSpacing: 100,
              treeSpacing: 200,
              blockShifting: true,
              edgeMinimization: true,
              parentCentralization: true,
              direction: "UD", // UD, DU, LR, RL
              sortMethod: "hubsize", // hubsize, directed
              shakeTowards: "leaves", // roots, leaves
            },
          },
          nodes: {
            // physics: false,
            shape: "circle",
            // color: "red"
            font: {
              // color: "green",
              background: "none",
              strokeWidth: 0,
              size: 10,
            },
            widthConstraint: { maximum: 50 },
            scaling: {
              min: 10,
              max: 30,
              label: {
                enabled: false,
                min: 14,
                max: 30,
                maxVisible: 30,
                drawThreshold: 5,
              },
              customScalingFunction: function (min, max, total, value) {
                if (max === min) {
                  return 0.5;
                } else {
                  let scale = 1 / (max - min);
                  return Math.max(0, (value - min) * scale);
                }
              },
            },
            shadow: {
              enabled: false,
              color: "rgba(0,0,0,0.5)",
              size: 10,
              x: 5,
              y: 5,
            },
            shape: "ellipse",
            shapeProperties: {
              borderDashes: false, // only for borders
              borderRadius: 6, // only for box shape
              interpolation: false, // only for image and circularImage shapes
              useImageSize: false, // only for image and circularImage shapes
              useBorderWithImage: false, // only for image shape
            },
            value: 1,
          },
          edges: {
            length: 300,
            arrows: {
              to: {
                enabled: true,
                imageHeight: 5,
                imageWidth: 1,
              },
            },
            smooth: {
              enabled: true,
              // type: "continuous",
              roundness: 0.5,
            },
            font: {
              // color: "green",
              // background: "none",
              // strokeWidth: 0,
              size: 8,
            },
          },
        },
        // visConfig: {
        //   nodes: {
        //     // label:'name',
        //     shape: "circle",
        //     physics: false,
        //     // color: "red"
        //   },
        //   edges: {
        //     // label:"name",
        //     arrows: {
        //       to: {
        //         enabled: true,
        //       },
        //     },
        //     font: {
        //       color: "#000",
        //       size: 10,
        //     },
        //     // physics: true,
        //     smooth: {
        //       enabled:false,//状态开启
        //             // type:'continuous',
        //             forceDirection:true,
        //             roundness:'0',
        //     },
        //   },
        //   physics: {
        //     enabled: false,
        //   },
        // },
        // isConfig: {
        // edges: {
        //     color: "dimgray",
        //     selectionWidth: 7,
        // },
        // nodes: {
        //     shape: "dot",
        //     labelHighlightBold: true,
        //     scaling: {
        //         // label: false
        //         label: {
        //             min: 13,
        //             // max: 20,
        //             // drawThreshold: 5,
        //             // maxVisible: 360
        //         }
        //     }
        // },
        // physics: {
        //     barnesHut: {
        //         // centralGravity: .5,
        //         // springLength: 400,
        //         springConstant: 0.3,
        //         avoidOverlap: 0.6,
        //         damping: 1
        //     },
        // },
        // interaction: {
        //     hover: true,
        //     hoverConnectedEdges: true,
        //     multiselect: true,
        // },
        // manipulation: {
        //     deleteNode: true,
        //     deleteEdge: true,
        //     initiallyActive: true,
        // },
        // configure: {
        //     filter: "physics",
        //     showButton: true
        // }
        // interaction: {
        // hover: true,
        // hoverConnectedEdges: true,
        // selectConnectedEdges: false,

        // multiselect: 'alwaysOn',
        // zoomView: false,
        // experimental: { }
        // },
        // physics: {
        //     enabled: false,
        //   },
        // nodes: {
        //     mass: 20,
        //     shape: 'neo',
        //     labelHighlightBold: false,
        //     widthConstraint: {
        //         maximum: 40
        //     },
        //     heightConstraint: {
        //         maximum: 40
        //     },
        //     physics: {
        //     enabled: false,
        //   },
        // },
        // edges: {
        //     hoverWidth: 0,
        //     selectionWidth: 0,
        //     // smooth: {
        //     //     type: 'continuous',
        //     //     roundness: 0.15
        //     // },
        //     font: {
        //         size: 9,
        //         strokeWidth: 0,
        //         align: 'top'
        //     },
        //     color: {
        //         inherit: false
        //     },
        //     arrows: {
        //         to: {
        //             enabled: true,
        //             type: 'arrow',
        //             scaleFactor: 0.5
        //         }
        //     },
        //     physics: {
        //     enabled: false,
        //   },

        // },
        labels: {
          Person: {
            label: "name",
            title: "name",
            // weight: 5,
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#165184", // static property is a constant property for all nodes of the type
                shape: "circle",
                // font:{
                //   size: '1'
                // }
                font: {
                  color: "#ffffff",
                  background: "none",
                  strokeWidth: 0,
                  size: 12,
                },
              },
            },
          },
        },
        relationships: {
          RELATION: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          k1: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          k2: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          k3: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          k4: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          k5: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
                // smooth: {
                //   enabled: true,
                //   // type: "dynamic",
                //   roundness: 0.1,
                //   forceDirection: true,
                // },
              },
            },
          },
          Sister: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
              },
            },
          },
          Relative: {
            label: "name",
            [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
              static: {
                color: "#8aaa9f", // static property is a constant property for all nodes of the type
              },
            },
          },
        },
        arrows: true,
        // hierarchical: true,
        // hierarchical_sort_method: "directed",
        initialCypher: this.cypherData,
      };

      return config;
    },
  },
};
</script>
<style lang="less" scoped>
#viz {
  width: 100%;
  height: 50vh;
  border: 1px solid lightgray;
  font: 20pt arial;
}
// #viz {
//   width: 100%;
//   height: 100vh;
//   border: 1px solid lightgray;
//   font: 20pt arial;
// }
</style>
