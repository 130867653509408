import Vue from "vue";
import VueRouter from "vue-router";
import Neo4J from "../views/Neo4J.vue";
import MapView from "../views/MapView.vue";
import fileSelect from "../components/fileSelect.vue"
import heatSelector from "../components/heatSelector.vue"
import showBox from "../components/showBox.vue"

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  //登陆页面
  {
    path: "/",
    name: "Login",
    component: () => import('@/views/login/login.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/test",
    name: "test",
    component: () => import('@/components/pdfShow.vue'),
    meta: { keepAlive: true }
  },

  // 注册页面
  {
    path: "/Register",
    name: "Register",
    component: () => import('@/views/login/register.vue'),
    meta: { keepAlive: true }
  },
  // 注册页面
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import('@/views/login/changepassword.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import('@/views/Home.vue'),
    meta: { keepAlive: true ,
            requireAuth:true}
  },

  {
    path: "/Neo4J",
    name: "Neo4J",
    component: Neo4J
  },
  {
    path: "/MapView",
    name: "MapView",
    component: MapView
  },
  {
    path: "/fileSelect",
    name: "fileSelect",
    component: fileSelect
  },
  {
    path: "/heatSelector",
    name: "heatSelector",
    component: heatSelector
  },
  {
    path: "/showBox",
    name: "showBox",
    component: showBox
  },
  {
    path: "/legend",
    name: 'legend',
    component: ()=> {
      return import ("@/components/legend.vue")
    }
  },
  {
    path: "/commanderdetail",
    name: 'commanderdetail',
    component: ()=> {
      return import ("@/components/CommanderDetail.vue")
    }
  },
  {
    path: "/totalcommander",
    name: 'totalcommander',
    component: ()=> {
      return import ("@/components/TotalCommander.vue")
    }
  },
  {
    path: "/portSelector",
    name: 'portSelector',
    component: ()=> {
      return import ("@/components/portSelector.vue")
    }
  },
  {
    path: "/close",
    redirect: "/Home"
  },
  {
    path: "/shipdetail",
    name: 'shipdetail',
    component: ()=> {
      return import ("@/components/shipDetail.vue")
    }
  },
  {
    path: "/legendreal",
    name: 'legendreal',
    component: ()=> {
      return import ("@/components/legend-real.vue")
    }
  },
  {
    path: "/trackCom",
    name: 'trackCom',
    component: ()=> {
      return import ("@/components/trackCompareSelector.vue")
    }
  },
  {
    path: "/legendcop",
    name: 'legendcop',
    component: ()=> {
      return import ("@/components/legend-cop.vue")
    }
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  console.log("即将跳转，原路由: " + from.name + " ---------> " + to.name);

  let accessToken = localStorage.getItem("accessToken")

  if(from.name == null && to.name == "Login"){
    if(accessToken != null){
      next({path:'/Home'})
      return
    }
  }

  if(from.meta.requireAuth != true && to.meta.requireAuth!=true){
    next()
    return
  }

  if(to.meta.requireAuth==true){
    if(accessToken == null || accessToken == undefined || accessToken == ""){
      next({path:'/'})
    }
  }

  if(to.name === "Home"){
    to.meta.keepAlive = true;
  }
  next();
});

export default router;
