<template>
    <div>
        <el-switch
  v-model="value"
  active-color="#8bb035"
  inactive-color="#666666"
  @change="change">
</el-switch>
    </div>
  </template>
  
  <script>
import { emit } from 'process';

  export default {
    name: 'sw',
    props: {
      
    },
    data() {
      return {
        value: true,
      }
    },
    methods: {
      change() {
        !this.value;
        this.$emit("valueChange", this.value)
      }
    }
  }
  </script>
  
  <style scoped>

  </style>
  